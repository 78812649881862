import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { LocalStorageService } from '../storage-service/local-storage.service';
import { Router } from '@angular/router';
import { DiscussionSocketService } from '../discussion/discussion-socket.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isUserLoggedIn: any;
    public tempisLoggedIn: any[] = [];
    loginData: any;
    connected: any[] = [];
    apiUrl: string = environment.url;
    apiEndPoint = NAVIGATE_ROUTES;
    constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private discussionSocketService: DiscussionSocketService
    ) {}
    list: any[] = [];

    async login(
        userName: string,
        password: string,
        device_info: any,
        web_token: any
    ): Promise<Observable<any>> {
        try {
            const response: any = await this.http
                .post<any>(this.apiEndPoint.LOGIN, {
                    data: { email: userName, password: password },
                    device_info,
                    web_token,
                    device_type: 'web',
                })
                .pipe(
                    map((data) => {
                        if (data.data.token) {
                            this.localStorage.setLocalStore('authorization', data.data.token);
                        }
                        if (
                            data.data.verification_token == 1 ||
              data.data.verification_token == '1'
                        ) {
                            this.list.length = 0;
                            this.tempisLoggedIn.length = 0;
                            this.list.push(data.data);
                            this.tempisLoggedIn.push(true, data.data);
                            this.isUserLoggedIn = true;
                            localStorage.setItem(
                                'isUserLoggedIn',
                                this.isUserLoggedIn ? 'true' : 'false'
                            );
                            this.localStorage.setDataInIndexedDB(
                                'username',
                                data.data.user_name
                            );
                            this.localStorage.setDataInIndexedDB('loginUserData', data.data);
                            const stateObj = {
                                isUpgrade: false,
                            };
                            this.localStorage.setDataInIndexedDB('updateState', stateObj);
                        } else {
                            this.list.length = 0;
                            this.tempisLoggedIn.length = 0;
                            this.tempisLoggedIn.push('error');
                        }
                        return this.tempisLoggedIn;
                    }),
                    catchError((_error) => {
                        this.list.length = 0;
                        this.tempisLoggedIn.length = 0;
                        this.tempisLoggedIn.push(false);
                        return this.tempisLoggedIn;
                    })
                )
                .toPromise();
            return response;
        } catch (error) {
            throw error;
        }
    }

    switchToken(body: any): Observable<any> {
        return this.http.post(this.apiEndPoint.SWITCH_COMPANY, body);
    }

    updateToken(body: any): Observable<any> {
        return this.http.post(this.apiEndPoint.UPDATE_TOKEN, body);
    }

    async logout() {
        this.discussionSocketService.socket?.disconnect();
        this.logoutApi();
        this.router.navigate(['/']);
        this.localStorage.clearStorage();
        await this.localStorage.clearRequiredDataFromIndexedDB();
        window.location.reload();
        sessionStorage.clear();
    }

    async logoutApi(): Promise<Observable<any>> {
        const fcm_token = localStorage.getItem('fcm_token');
        let res: any[] = [];
        const url = `${this.apiEndPoint.LOGOUT}`;
        await this.apiService.post(url, { fcm_token }).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async resentVerifyEmail(email:string): Promise<Observable<any>> {
        let res:any;
        const url = `${this.apiEndPoint.RESENT_VERIFY_EMAIL_LINK}`;
        await this.apiService.post(url, {data:{email}}).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }
    async unsubscribeMail(companyId:any,userId:any,reason:string,reasonString?:string): Promise<Observable<any>> {
        let res:any;
        let url = `${this.apiEndPoint.UNSUBSCRIBE_MAIL}?company_id=${companyId}&user_id=${userId}&reason=${reason}`;
        if(reasonString){
            url += `&reasonString=${reasonString}`
        }
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }
}
