import {
    Component,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
    Injector,
    ElementRef,
} from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';
import 'quill-emoji';
import Quill from 'quill';
import ImageDropAndPaste from 'quill-image-drop-and-paste';
import MagicUrl from 'quill-magic-url';
Quill.register('modules/imageDropAndPaste', ImageDropAndPaste);
Quill.register('modules/magicUrl', MagicUrl);
import 'quill-mention';
import { ToastrService } from 'ngx-toastr';
import { SocketService } from '../../services/socket.service';
import { FILE_CONFIGURATION, MESSAGE_TYPES, constant } from '../../global/constants';
import { ApiService } from '../../services/api-service/api.service';
import { TranslateService } from '@ngx-translate/core';
import * as RecordRTC from 'recordrtc';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientInfoPreviewComponent } from '../client-info-preview/client-info-preview.component';
import { OrganizationSettingsService } from '../../services/organization-settings/organization-settings.service';
import { UserDetailsService } from '../../services/user-details.service';
import { NotificationService } from '../../services/notification/notification.service';
import { Store } from '@ngrx/store';
import { QuickReplyService } from '../../services/task-service/quick-reply.service';

@Component({
    selector: 'app-comment-editor',
    templateUrl: './comment-editor.component.html',
    styleUrls: ['./comment-editor.component.scss'],
})
export class CommentEditorComponent implements OnInit {
    MESSAGE_TYPES: any;
    fileURL: any = [];

  @Input() selectedReplyMessage: any;
  @Input() dropFiles: any = [];

  @ViewChild(QuillEditorComponent, { static: true })
      editor: QuillEditorComponent;
  @ViewChild(QuillEditorComponent) quillEditor: QuillEditorComponent;
  @ViewChild('uploadFile') myInputVariable: ElementRef;
  @Input() content = '';
  matContent = '';
  users: any = [];
  selectedMentionUsers: any = [];
  mentionUsersList: any;
  canAddComment = true;
  refreshCommentSubscription = new Subscription();
  isMentionUserSelect = true;
  typeString = '';
  progress: number;
  isrecording = false;
  isClientInfoShare = false;
  companyId: any;
  userId: any;
resetClient:boolean=false;
  @Output('isCancelReply') isCancelReply = new EventEmitter();
  @Input() selectedMessage: any;

  @Input() mentionUsers: any;

  userIds: any = [];
  groupIds: any = [];
  message = '';
  quickReplyList: any = [];
  quickReplyFilter: any = [];
  isEnableQuickReply = false;
  myFiles: any;
  bigFile = false;
  updateFiles: any[] = [];
  @Output() commentData: EventEmitter<any> = new EventEmitter<any>();
  @Output() userTyping: EventEmitter<any> = new EventEmitter<any>();

  isDisable = false;
  isStart = false;
  recorder: any;
  stream: any;
  recordingValue = '00:00';
  recordingIntervalId: any;
  allFiles: any = [];
  public utils: UtilsService;
  fileData: any = [];
  audioFileData: any;
  allDocFiles: any = [];
  clientMatPreview: any = [];
  ClientInfoPreviewComponent: MatDialogRef<ClientInfoPreviewComponent>;
  userRolePermission: any;
  taskState:any;

  constructor(
    private elementRef: ElementRef,
    private ToastService: ToastrService,
    private socket: SocketService,
    private apiService: ApiService,
    public translate: TranslateService,
    private injector: Injector,
    public utilsService: UtilsService,
    private apisevice: ApiService,
    public matDialog: MatDialog,
    public organizationSettings: OrganizationSettingsService,
    private userDetail: UserDetailsService,
    public notificationService: NotificationService,
    public quickReplyService: QuickReplyService,
    private store: Store<{ taskState: TaskState }>
  ) {
      this.utils = injector.get<UtilsService>(UtilsService);
      // 0.3 means it will go up to 100 by adding 0.3 everysecond for 300 seconds(5 minit)
      this.progress = 0.3;
      this.MESSAGE_TYPES = MESSAGE_TYPES;
      this.store.select("taskState").subscribe((ele: any) => {
        if (!ele?.taskState) return;
        this.taskState = ele?.taskState;
      });
  }

  async ngOnChanges() {
      if (this.dropFiles) {
          if (this.dropFiles.length > 0) {
              this.dropFiles.forEach(async (element: any) => {
                  element.image = await this.utilsService.getDocumentIcon(
                      element,
                      false
                  );
              });

              this.allDocFiles = this.allDocFiles.concat(this.dropFiles);
              this.updateFiles = this.updateFiles.concat(this.dropFiles);
              this.dropFiles.forEach((files: any) => {
                  this.fileURL.push(files.result);
                  this.fileData.push(files.files);
              });
              this.dropFiles = [];
          }
      }
  }

  async ngOnInit() {
      this.companyId = await this.userDetail.getSelectedCompanyId();
      this.userId = await this.userDetail.encryptUserId;
      this.userRolePermission = await this.userDetail.getUserRolePermissions();
      try {
          this.getGeneralSetting();
      } catch (error) {
            console.log(error)        
      }
  }

  modules = {
      magicUrl: true,
      mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@'],
          onSelect: (item: any, insertItem: any) => {
              this.isMentionUserSelect = false;
              const selected_data = JSON.parse(item.id);
              const data: any = {
                  id: `{{${selected_data.id}}}`,
                  name: selected_data.name,
                  user_id: selected_data.user_id,
                  group_id: selected_data.group_id > 0 ? selected_data.group_id : null,
              };
              if (data.user_id) {
                  this.userIds.push(selected_data.id);
                  data['userIds'] = this.userIds;
              }
              if (data.group_id > 0) {
                  this.groupIds.push(selected_data.id);
                  data['groupIds'] = this.groupIds;
              }
              this.selectedMentionUsers.push(data);
              const dataType = selected_data.user_id ? 'user' : 'group';
              item.value =
          '<span data-type=' +
          dataType +
          '></span><b>' +
          selected_data.name +
          '</b>';
              const editor = this.editor.quillEditor;
              insertItem(item);
              // necessary because quill-mention triggers changes as 'api' instead of 'user'
              editor.insertText(editor.getLength() - 1, '', 'user');
          },
          onClose: (item: any, insertItem: any) => {
              setTimeout(() => {
                  this.isMentionUserSelect = true;
              }, 250);
          },
          source: (searchTerm: any, renderList: any) => {
              this.users = [];
              this.modifyDropdownData(this.users, this.mentionUsers);
              this.mentionUsersList = this.users;

              if (searchTerm.length === 0) {
                  if (this.mentionUsersList.length <= 1) {
                      return;
                  }
                  renderList(this.mentionUsersList, searchTerm + '=');
              } else {
                  const matches: any = [];
                  let isGroup = false;
                  let isMember = false;
                  this.mentionUsersList.forEach((entry: any) => {
                      if (
                          entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              entry.id == null
                      ) {
                          if (entry?.id?.indexOf('user_id') >= 0) {
                              isMember = true;
                          }
                          if (entry?.id?.indexOf('group_id') >= 0) {
                              isGroup = true;
                          }
                          matches.push(entry);
                      }
                  });
                  matches.forEach((entry: any, index: any) => {
                      if (
                          (!isGroup && entry.value == 'GROUP') ||
              (!isMember && entry.value == 'MEMBER')
                      ) {
                          matches.splice(index, 1);
                      }
                  });
                  if (!isGroup && !isMember) {
                      matches.splice(0, 1);
                  }
                  renderList(matches, searchTerm + '&');
              }
          },
      },
      keyboard: {
          bindings: {
              enter: {
                  key: 13,
                  handler: () => {},
              },
          },
      },
      toolbar: false,
      'emoji-toolbar': true,
      'emoji-textarea': true,
      'emoji-shortname': true,
      imageDropAndPaste: {
          handler: async (file: any, type: any, imageData: any) => {
              const fileData = imageData.toFile();
              if (fileData) {
                  fileData.image = await this.utilsService.getDocumentIcon(
                      fileData,
                      false
                  );
                  this.allDocFiles.push(fileData);
                  this.updateFiles.push(fileData);
                  this.allDocFiles.forEach((files: any) => {
                      this.fileURL.push(files.result);
                      this.fileData.push(files.files);
                  });
              }
          },
      },
  };

  modifyDropdownData(arr: any, data: any) {
      let isGroup = false;
      let isMember = false;
      arr.push({ value: 'MEMBER', disabled: true });
      data.forEach((user: any) => {
          if (user.user_id) {
              isMember = true;
              const member_exist = arr.find((a: any) => a.id === JSON.stringify(user));
              if (member_exist == undefined) {
                  arr.push({
                      id: JSON.stringify(user),
                      value: user.value,
                  });
              }
          }
      });
      arr.push({ value: 'GROUP', disabled: true });
      data.forEach((user: any) => {
          if (user.group_id) {
              isGroup = true;
              arr.push({
                  id: JSON.stringify(user),
                  value: user.value,
              });
          }
      });
      arr.forEach((entry: any, index: any) => {
          if (
              (!isGroup && entry.value == 'GROUP') ||
        (!isMember && entry.value == 'MEMBER')
          ) {
              arr.splice(index, 1);
          }
      });
  }

  onSelectionChanged = (event: any) => {};

  onContentChanged = async (event: any) => {
      this.typeString = event.html;
      this.userTyping.emit(event.html);
      if (event.editor.getLength() < 10000) {
          this.message = event.html;
      } else {
          this.ToastService.error('Characters max limit exceeds');
          return;
      }
  };

  onStartTyping(event: any) {
      if (this.quickReplyList?.length > 0) {
          this.quickReplyCheck(event);
      }
  }

  ngAfterViewInit() {
      const editor = this.elementRef.nativeElement.querySelector('.ql-editor');
      const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
              if (mutation.type === 'childList') {
                  this.isHyperlink();
              } else {
                  return;
              }
          });
      });
      observer?.observe(editor, { childList: true, subtree: true });
  }

  isHyperlink() {
      const editor = this.elementRef.nativeElement.querySelector('.ql-editor');
      const text = editor.innerText;

      if (text.includes('www.')) {
          const replacedText = text.replace(
              /(www\.[^\s]+)/g,
              '<a href="http://$1" rel="noopener noreferrer" target="_blank">$1</a>'
          );
          editor.innerHTML = replacedText;
      }

      if (text.includes('https://')) {
          const replacedText = text.replace(
              /(https?:\/\/)(?!www\.)([^\s]+)/g,
              '$1www.$2'
          );
          editor.innerHTML = replacedText;
      }
  }

  handleCommentManipulation(comment: string) {
      const domParser = new DOMParser();
      const parseHTMLDOMObject = domParser.parseFromString(comment, 'text/html');
      const mentions = parseHTMLDOMObject.querySelectorAll('.mention');
      if (mentions) {
          mentions.forEach((item) => {
              if (item.getAttribute('data-id')) {
                  const userData = JSON.parse(item.getAttribute('data-id'));
                  item.setAttribute('data-id', userData.id);
              }
          });
      }
      return parseHTMLDOMObject.querySelector('body').innerHTML;
  }

  addMentionComment(event: any, type: any) {
      const commentText: any = this.handleCommentManipulation(this.message);
      const clientIds = _.map(this.clientMatPreview, 'id');

      if (type === 'click') {
          this.commentData.emit({
              comment: (commentText === 'null' || this.removeTags(commentText)?.trim()?.length<1)? '' : commentText,
              ids: this.userIds,
              msg: '',
              allFiles: this.allFiles,
              updateFiles: this.updateFiles,
              mention_user: this.userIds,
              mention_group: this.groupIds,
              client_contact: clientIds,
          });
          this.content = '';
          this.allFiles = [];
          this.updateFiles = [];
          this.selectedMessage = null;
          this.allDocFiles = [];
          this.clientMatPreview = [];
          this.apisevice.resetClientList.next(this.clientMatPreview);
      }
  }

  removeTags(str:any) {
	if ((str === null) || (str === ''))
		return "";
	else
		str = str?.toString();
	return str?.replace(/(<([^>]+)>)/ig, '');
}

  openContactDetails(client_id: any) {
      this.ClientInfoPreviewComponent = this.matDialog.open(
          ClientInfoPreviewComponent,
          {
              disableClose: true,
              panelClass: [
                  'custom-modal',
                  'custom-modal-md',
                  'new-client-info-cls-add',
              ],
              data: { clientId: client_id },
          }
      );
      this.ClientInfoPreviewComponent.afterClosed().subscribe(async (res) => {
          if (res) {
          }
      });
  }

  // quick Reply
  async quickReplyCheck(event: any) {
      let commentText;
      if (event?.target?.innerText?.trim()) {
          commentText = event.target.innerText;
      }
      this.quickReplyFilter = [];
      if (commentText && commentText.charAt(0) === '/') {
          if(!this.quickReplyList.length){
            await this.getQuickReplyList();
          }
          this.isEnableQuickReply = true;
          const quickReplyString = commentText.replace('/', '').toLocaleLowerCase();
          if (quickReplyString) {
              this.quickReplyList.filter((it: any) => {
                  if (it.title) {
                      const value = it.title
                          .toLocaleLowerCase()
                          .includes(quickReplyString);
                      const hasMessage = it.message
                          .toLocaleLowerCase()
                          .includes(quickReplyString);
                      if (value || hasMessage) {
                          this.quickReplyFilter.push(it);
                      }
                  }
              });
          } else {
              this.quickReplyFilter = this.quickReplyList;
          }
      } else {
          this.isEnableQuickReply = false;
      }
  }

  async open(e: any) {
      const quillControll: any = document.querySelector(
          '.ql-mention-list-container-top'
      );
      if (quillControll) {
          quillControll.style['visibility'] = 'hidden';
      }
      if (this.isrecording) {
          this.stopRecording();
      } else {
          this.addMentionComment(e.target.parentNode, 'click');
      }
  }
  async cancelRecording() {
      this.isDisable = false;
      this.isStart = false;
      this.allFiles = [];
      await this.recorder.stop();
      this.recordingValue = '00:00';
      clearInterval(this.recordingIntervalId);
      if (this.stream) {
          this.stream.getAudioTracks().forEach((track: any) => {
              track.stop();
          });
          this.stream = null;
      }
      this.progress = 0.3;
      this.isrecording = false;
  }

  async uploadFiles(fileData: any) {
      if(fileData?.length > FILE_CONFIGURATION.DEFAULT_NUMBER_OF_FILES_UPLOADS){
        this.apiService.showError(this.translate.instant('MAXIMUM_FILES_UPLOAD_LIMIT'));
        return;
      }
      const myFilesTemp: any = Array.from(fileData);
      const companyAllowFileSize:any = await this.userDetail.getSelectedCompanyFileSize();
      let fileSize = 0;
      for (const file of myFilesTemp) {
          fileSize += file.size;
      }
      const mb = 0.000001;
      const result = fileSize * mb;

      if (result > companyAllowFileSize) {
          this.bigFile = true;
          this.apiService.showError(this.translate.instant('DYNAMIC_FILE_SIZE_VALIDATION') + ` ${companyAllowFileSize} ` +'MB');
          return;
      } else {
          this.bigFile = false;
          this.updateFiles.push([...fileData]);
          this.updateFiles = this.updateFiles.flat();
          this.myFiles = myFilesTemp;
          this.commentData.emit(this.updateFiles);
      }
  }

  async uploadDocument(event: any) {
      setTimeout(async () => {
        if (!this.bigFile) {
            this.apisevice.setFileSize = constant.setFileSize;
            if (event.target.files && event.target.files.length > 0 && event.target.files.length <= FILE_CONFIGURATION.DEFAULT_NUMBER_OF_FILES_UPLOADS) {
                const img = await this.utilsService.uploadDocs(event);
                this.allDocFiles = this.allDocFiles.concat(img);
                this.allDocFiles.forEach(async (element: any) => {
                    element.image = await this.utilsService.getDocumentIcon(
                        element.files,
                        false
                    );
                });
                img.forEach((files: any) => {
                    this.fileURL.push(files.result);
                    this.fileData.push(files.files);
                });
            }
        }
    },300);
  }

  async startRecording() {
      this.isDisable = true;
      this.isrecording = true;
      await navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
              this.isStart = true;
              this.stream = stream;
          })
          .catch((err) => {
              return this.ToastService.error('Requested device not found');
          });
      this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          numberOfAudioChannels: 1,
          audioBitsPerSecond: 128000,
          disableLogs: true,
          timeSlice: 1000,
          frameInterval: 90,
      });
      this.recorder.record();
      this.recordingIntervalId = setInterval(() => {
          this.recordingValue = this.utils.addTimes(this.recordingValue, '00:01');
          this.progress = this.progress + 0.3;
          if (this.recordingValue == '00:05:00') {
              this.stopRecording();
          }
      }, 1000);
  }

  async stopRecording() {
      this.isDisable = false;
      this.isStart = false;
      this.allFiles = [];
      await this.recorder.stop();
      this.recordingValue = '00:00';
      clearInterval(this.recordingIntervalId);
      if (this.stream) {
          this.stream.getAudioTracks().forEach((track: any) => {
              track.stop();
          });
          this.stream = null;
      }
      const todayDate: any = moment().format('DDMMYYYYHHMMSS');
      const filename = 'file name'; //constant.AUDIO_RECORDING + '_' + this.taskId + '_' + Math.round(Math.random() * todayDate)
      this.recorder.stop(async (blob: any) => {
          const file = new File([blob], this.utils.getFileName('wav'), {
              type: 'audio/wav',
          });
          const json = {
              name: filename + '.wav',
              size: blob.size,
              type: blob.type,
          };
          this.fileData.push(json);
          const audio: any = await this.utils.audioFile(file);
          this.audioFileData = audio.result;
          this.allFiles = this.allFiles.concat(audio);
          this.addMentionComment(null, 'click');
      });
      this.progress = 0.3;
      this.isrecording = false;
  }

  isCancelMsgReply() {
      this.selectedMessage = null;
      this.isCancelReply.emit(true);
  }

  removeImage(index: number) {
      this.allDocFiles.splice(index, 1);
      this.updateFiles.splice(index, 1);
      this.myInputVariable.nativeElement.value = '';
  }

  redirectMap(lat: any, lon: any) {
      window.open(`https://www.google.com/maps/dir/${lat},+${lon}`, '_blank');
  }
  getSelectedClient(cientList: any) {
      this.clientMatPreview = cientList;
  }

  async getGeneralSetting() {
    if(this.taskState?.generalMasterConfig){
        this.isClientInfoShare =
        (this.taskState?.generalMasterConfig?.is_client_permission &&
            this.taskState?.generalMasterConfig?.companies_detail?.is_having_clients &&
            this.taskState?.generalMasterConfig?.shared_client_details?.includes(
              this.userRolePermission.memberId
          )) ||
        ((this.userRolePermission?.permissions?.Clients ||
          this.userRolePermission?.superAdmin) &&
          this.taskState?.generalMasterConfig?.companies_detail?.is_having_clients)
            ? true
            : false;
    }else{
        (
            await this.organizationSettings.getGeneralSettings(
                this.companyId,
                this.userId
            )
        ).subscribe(async (res: any) => {
            this.isClientInfoShare =
          (res?.is_client_permission &&
            res?.companies_detail?.is_having_clients &&
            res?.shared_client_details?.includes(
                this.userRolePermission.memberId
            )) ||
          ((this.userRolePermission?.permissions?.Clients ||
            this.userRolePermission?.superAdmin) &&
            res?.companies_detail?.is_having_clients)
              ? true
              : false;
        });
    }
  }

  async getQuickReplyList() {
      (await this.quickReplyService.quickReplyList()).subscribe((data: any) => {
          this.quickReplyList = data?.quickReplyList;
      });
  }

  public handleStaticResultSelected(result: any) {
      if (result.message) {
          this.quillEditor.quillEditor.setContents(
              this.quillEditor.quillEditor.clipboard.convert(result.message)
          );
          this.isEnableQuickReply = false;
      }
  }
  resetOnClick(){
    this.resetClient=true
  }
}
