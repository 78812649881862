import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/storage-service/local-storage.service';
import { NO_STORE_CATCH_URL_DATA } from '../global/constants';

@Injectable()
export class RequestInterceptorInterceptor implements HttpInterceptor {
    constructor(private localStorageService: LocalStorageService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authorization = this.localStorageService.getLocalStore('authorization');
        const userIp = this.localStorageService.getLocalStore('userIp');
        let headers = request.headers
            .set('authorization', 'Bearer ' + authorization)
            .set('X-Custom-Header', `${userIp}`)
            .set('X-Custom-Header-device', 'web');

        // Conditionally add 'Cache-Control' header
        if (request.url.includes(NO_STORE_CATCH_URL_DATA[0])) {
            headers = headers.set('Cache-Control', 'no-cache');
        }
        const authReq = request.clone({ headers });

        return next.handle(authReq);
    }
}
