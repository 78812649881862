<div class="header-elements mub-header-elements">
  <div class="d-flex align-items-center">
    <img src="../../../../assets/icons/menu.svg" width="25px" height="auto" class="me-2" (click)="clickToggle()"
      alt="menu" />
    <img src="./assets/images/new-logo-cropped.webp" class="TaskOPad-icon" alt="TaskOPadLogo" draggable="false"
      routerLink="/dashboard" />
  </div>
  <div class="right-align">
    <ul class="list-unstyled d-flex align-items-center mb-0">
      <li class="ms-3" *ngIf="showAnnouncement">
        <a href="">
          <img class="accouncement-icon-cls" src="assets/icons/announcement.svg" alt="icon" draggable="false" (click)="openAnnouncement()" />
        </a>
      </li>
      <li class="ms-3 bell-icons">
        <a *ngIf="unreadNotificationCount == 0">
          <img src="assets/icons/bell.svg" alt="icon" draggable="false" (click)="redirectToNotificationList()" />
        </a>
        <a *ngIf="unreadNotificationCount > 0" (click)="isShowNotificationList = true">
          <img src="assets/icons/bell.svg" alt="icon" draggable="false" [matMenuTriggerFor]="notification" />
          <div class="unread-notification-count" *ngIf="
              unreadNotificationCount - 1 != 0 && unreadNotificationCount > 99
            ">
            <span>99+</span>
          </div>
          <div class="unread-notification-count" *ngIf="
              unreadNotificationCount - 1 != 0 && unreadNotificationCount <= 99
            ">
            {{ unreadNotificationCount }}
          </div>
          <div class="unread-notification-count" *ngIf="unreadNotificationCount - 1 == 0">
            {{ unreadNotificationCount }}
          </div>
        </a>
        <mat-menu #notification xPosition="before"
          class="custom-dropdown-menu custom-dropdown-menu-ctm new-common-notification-cls-add">
          <app-notification-preview *ngIf="isShowNotificationList"></app-notification-preview>
        </mat-menu>
      </li>
      <li class="ms-3">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="profile-dropdown d-flex">
          <img [src]="userImgPath" *ngIf="
              userImgPath?.charAt(0) !== '#' && userImgPath?.charAt(0) == 'h'
            " class="user-img" alt="assignee profile" draggable="false" />
          <div *ngIf="
              userImgPath.charAt(0) == '#' ||
              !userImgPath ||
              userImgPath?.charAt(0) == 'u'
            " class="user-short-name mr-0" [ngStyle]="{ 'background-color': '#DC3535' }">
            {{ utils.forShortName(user) }}
          </div>
          <div class="ms-2 user-name text-capitalize">{{ user }}</div>
          <span class="ms-2 user-name">
            <img src="assets/icons/downfilledarrow.svg" alt="icons" draggable="false" />
          </span>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="custom-dropdown-menu">
          <ng-container *ngFor="let submenu of headerNav[0]; let i = index">
            <a [href]="submenu.route" class="submenu-elements">
              <button mat-menu-item *ngIf="submenu.name !== 'HELP' && submenu.name !== 'Logout'">
                {{ submenu.name | translate }}
              </button>
            </a>
            <button mat-menu-item routerLink="{{ submenu.route }}" *ngIf="submenu.name == 'Logout'"
              (click)="authService.logout()">
              {{ submenu.name | translate }}
            </button>

            <button mat-menu-item mat-button mat-flat-button (click)="openGuideModal()" *ngIf="submenu.name == 'HELP'">
              {{ submenu.name | translate }}
            </button>
          </ng-container>
        </mat-menu>
      </li>
    </ul>
  </div>
</div>