import { ClientInfoPreviewComponent } from './../../../shared/components/client-info-preview/client-info-preview.component';
import { ImagePreviewComponent } from './../../../shared/components/image-preview/image-preview.component';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
    ElementRef,
    ViewChild,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    AfterViewInit,
} from '@angular/core';
import {
    discussionSocketEvents,
    DISCUSSION_COMMENT_TYPE,
    MESSAGE_TYPES,
    IMAGE_UPLOAD_TYPE,
} from 'src/app/shared/global/constants';
import { DiscussionSocketService } from 'src/app/shared/services/discussion/discussion-socket.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import * as moment from 'moment';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { UserState } from 'src/app/shared/ngrx/reducer/user-state.reducer';
import { Store } from '@ngrx/store';
import { DiscussionService } from 'src/app/shared/services/discussion/discussion.service';
import { DmsService } from 'src/app/shared/services/dms-service/dms.service';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { LocalStorageService } from 'src/app/shared/services/storage-service/local-storage.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { taskState } from 'src/app/shared/ngrx/reducer/task-state.reducer';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-chat-room',
    templateUrl: './chat-room.component.html',
    styleUrls: ['../discussion.component.scss', './chat-room.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        DefaultMatCalendarRangeStrategy,
        MatRangeDateSelectionModel,
    ],
})
export class ChatRoomComponent implements OnInit, OnChanges, AfterViewInit {
    scrollId: any = null;
    dropFiles: any = [];
    hasBootomTouch = false;
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  MESSAGE_TYPES: any;

  modalScrollDistance = 8;
  modalScrollThrottle = 50;
  currentCount = 25;
  currentPage = 1;
  hasNextValue = true;

  search = '';
  highlightMessage: string;
  uploadFileFakeData: any = [];

  openSearchbar = false;
  searchResponse: { [key: string]: any } = { count: 0, data: [] };
  searchNextStack: Array<string> = [];
  searchPreviousStack: Array<string> = [];

  showLoaddingConversation = false;
  replyMessage: any;
  removedMessage: any;
  selectedMessage: any = {};
  selectedCompanyId: any;
  clientListData: any = [];
  @ViewChild('chatRoomScroll') private chatRoomScroll: ElementRef;
  @Input() selectedRoom: any;
  @Output() handelOpenDetails: any;
  @Output() handelNewMessage = new EventEmitter<any>();
  @Input() isShowing: any = false;
  currentActiveUserId: number;
  selectedChatRoomDetails: any = {};
  discussionMessages: any[] = [];
  usersTyping: any[] = [];
  @Output('openDetailWindow') openDetailWindow = new EventEmitter();
  imagePreviewComponent: MatDialogRef<ImagePreviewComponent>;
  ClientInfoPreviewComponent: MatDialogRef<ClientInfoPreviewComponent>;
  onlineArr: any = [];
  mentionUsers: any = [];
  is_change = true;
  dateRange: FormGroup;
  dateRanges: DateRange<Date>;
  currentIndex = 0;
  currentlyPlayingIndex = -1;
  todayDate: any = new Date();
  startDate: any;
  endDate: any;
  originalGroupList: any;
  @ViewChild('myInput', { static: false }) myInput: ElementRef;
  subscription: Subscription;
  taskState: any;
  chatRoomAccesses:any;

  constructor(
    private localStorage: LocalStorageService,
    private discussionSocketService: DiscussionSocketService,
    private userDetailService: UserDetailsService,
    public utilsService: UtilsService,
    public matDialog: MatDialog,
    private clipboard: Clipboard,
    private store: Store<{ userState: UserState }>,
    public discussionService: DiscussionService,
    private taskService: TasksService,
    public formBuilder: FormBuilder,
    private dmsService: DmsService,
    public ch: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private store1: Store<{ taskState: taskState }>
  ) {
      this.currentActiveUserId = this.userDetailService.userId;
  }

  public getSantizeUrl(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async ngOnChanges(changes: any): Promise<void> {
      if (
          (this.selectedChatRoomDetails?._id &&
        changes?.selectedRoom?.currentValue?._id !==
          this.selectedChatRoomDetails?._id) ||
      !this.selectedChatRoomDetails?._id
      ) {
          this.MESSAGE_TYPES = MESSAGE_TYPES;
          if (this.originalGroupList?.length == 0) {
              try {
                (await this.taskService.getGroupList(this.selectedCompanyId)).subscribe(
                    async (resData: any) => {
                        this.originalGroupList = resData;
                    }
                );
            } catch (error) {}
          }
          if (changes?.selectedRoom?.currentValue) {
              this.currentPage = 1;
              this.dateRange?.controls['from_date'].patchValue(null);
              this.dateRange?.controls['to_date'].patchValue(null);
              this.dateRanges = new DateRange<Date>(null, null);
              this.is_change = false;
              this.hasNextValue = true;
              this.selectedChatRoomDetails = {
                  ...changes?.selectedRoom?.currentValue,
              };
              this.chatRoomAccesses = _.uniqBy(this.selectedChatRoomDetails?.accesses, 'user_id');
              this.mentionUsers = [];
              this.selectedChatRoomDetails?.accesses?.forEach((element: any) => {
                  if (element.group_id > 0) {
                      element.group_avatar = element?.group_name
                          ?.substring(0, 2)
                          ?.toLocaleUpperCase();
                  }
                  if (
                      element?.profile_image &&
            element?.profile_image?.charAt(0) != '#'
                  ) {
                      element.user_profile = `<img src="${element?.profile_image}" class="img-avatar only-use-this-for-modal"  alt="avatar-ics" draggable="false"/>`;
                  } else {
                      element.profile_image =
              element?.profile_image != null ? element?.profile_image : '#35226';
                      element.user_profile = `<span *ngIf="${
                          element?.profile_image?.charAt(0) == '#'
                      }" 
          class="user-short-name mr-0 custom-decreasing-space" 
          style="display:inline-grid !important;"
          data-type="group"
          [ngStyle]="{'background-color': ${element.profile_image}}">${
    element?.group_avatar
} </span> `;
                  }
                  if (element.group_id > 0) {
                      const groupExist = this.mentionUsers.find(
                          (item: any) => item.group_id == element.group_id
                      );
                      this.originalGroupList?.forEach((item: any) => {
                          if (element?.group_id == item?.id) {
                              element.group_name = item?.group_name;
                          }
                      });
                      if (groupExist == undefined) {
                          this.mentionUsers.push({
                              id: element.group_id,
                              group_id: element.group_id,
                              name: element.group_name,
                              value: `${element.user_profile} ${element.group_name}`,
                          });
                      }
                  }
              });
              this.selectedChatRoomDetails.accesses = _.unionBy(this.selectedChatRoomDetails?.accesses, 'user_id');
              this.selectedChatRoomDetails?.accesses?.forEach((element: any) => {
                  element.avatar = this.utilsService.forShortName(
                      element?.first_name + ' ' + element?.last_name
                  );
                  if (
                      element?.profile_image &&
            element.profile_image?.charAt(0) != '#'
                  ) {
                      element.user_profile = `<img src="${element?.profile_image}" class="img-avatar only-use-this-for-modal"  alt="avatar-ics" draggable="false"/>`;
                  } else {
                      element.profile_image =
              element.profile_image != null ? element.profile_image : '#35226';
                      element.user_profile = `<span *ngIf="${
                          element?.profile_image?.charAt(0) == '#'
                      }" 
          class="user-short-name mr-0 custom-decreasing-space" 
          data-type="user"
          style="display:inline-grid !important;"
          [ngStyle]="{'background-color': ${element.profile_image}}">${
    element?.avatar
} </span> `;
                  }
                  this.mentionUsers.push({
                      id: element.user_id,
                      user_id: element.user_id,
                      name: element.first_name + ' ' + element.last_name,
                      value: `${element.user_profile} ${
                          element.first_name + ' ' + element.last_name
                      }`,
                  });
              });
              if (this.selectedChatRoomDetails.type === 'MEMBER') {
                  this.selectedChatRoomDetails['otherMember'] =
            this.selectedChatRoomDetails.accesses.find(
                (item: any) => item.user_id !== this.currentActiveUserId
            );
              }
              this.discussionMessages = [];
              this.usersTyping = [];
              setTimeout(() => {
                  this.is_change = true;
              });

              this.discussionMessages = [];
              this.getMessageList();
              this.subscribeNewMessage();
              this.subscribeChatRoomTyping();

              this.search = null;
              this.highlightMessage = null;
              this.openSearchbar = false;
              this.searchResponse = { count: 0, data: [] };
              this.searchNextStack = [];
              this.searchPreviousStack = [];
              if (
                  this.selectedChatRoomDetails?.selectedTabType == 'MESSAGE' &&
          this.selectedChatRoomDetails?.searchText
              ) {
                  setTimeout(() => {
                      this.openSearchbar = true;
                      this.handelResetSearch();
                      this.search = this.selectedChatRoomDetails?.searchText;
                      this.handelSearchMessage();
                  }, 1200);
              }
          }
          if (!this.selectedChatRoomDetails?._id) {
              const localComment: any = await this.localStorage.getDataFromIndexedDB(
                  'tasklocalcomment'
              ).then;
              setTimeout(() => {
                  if (_.isArray(localComment)) {
                      this.discussionMessages = [...localComment];
                  }
              }, 100);
          }
      }
  }

  async ngOnInit() {
      this.store1.select('taskState').subscribe((ele: any) => {
          this.taskState = ele?.taskState['reportConfig'];
      });
      this.dateRangeFormInit();
      this.currentPage = 1;
      this.hasNextValue = true;
      this.selectedChatRoomDetails = { ...this.selectedRoom };
      this.discussionMessages = [];
      this.usersTyping = [];
      this.getMessageList();
      this.subscribeNewMessage();
      this.subscribeChatRoomTyping();
      this.subscribeRemoveMessageChatRoom();
      this.store.select('userState').subscribe((ele: any) => {
          this.onlineArr = ele.updateState;
      });
      this.selectedCompanyId =
      await this.userDetailService.getSelectedCompanyId();
      this.getGroupList();
      this.getclientList();
      this.subscription = this.taskService.checklistCommentSearchText.subscribe(
          (res: any) => {
              this.search = res;
              if (this.selectedChatRoomDetails.type == 'CHACKLIST') {
                  this.handelSearchMessage();
              }
          }
      );
  }

  ngAfterViewInit(): void {
      document
          .querySelector('.msgContentBox')
          .addEventListener('wheel', (event: any) => {
              if (event.wheelDelta) {
                  return event.wheelDelta > 0;
              }
              return event.deltaY < 0;
          });
  }

  async getGroupList() {
      // (await this.taskService.getGroupList(this.selectedCompanyId)).subscribe(
      //   async (resData: any) => {
      const groupData = this.taskState?.group_details;
      this.originalGroupList = groupData;
      // }
      // );
  }
  subscribeNewMessage() {
      const oldMessage: any = { message: {} };
      if (this.discussionSocketService?.socket) {
          this.discussionSocketService.socket.removeAllListeners(
              discussionSocketEvents.NEW_MESSAGE
          );
      }
      this.discussionSocketService.subscribe(
          discussionSocketEvents.NEW_MESSAGE,
          (response: any) => {
              // if (response?.message) {
              //   response.message = this.utilsService.parseHTMLManipulation(
              //     response.message,
              //     response.mention_user,
              //     response.mention_group
              //   );
              // }
              response.sender.profile_image =
          response?.sender?.profile_image == null ||
          response?.sender?.profile_image == undefined
              ? '#3477dc'
              : response?.sender?.profile_image;
              response.sender.avatar = this.utilsService.forShortName(
                  response?.sender?.first_name + ' ' + response?.sender?.last_name
              );
              if (oldMessage?.message._id !== response?._id) {
                  oldMessage.message = { ...response };
                  this.showLoaddingConversation = true;
                  this.discussionMessages = [
                      ...this.discussionMessages,
                      {
                          ...response,
                          time: new Date(response?.created_at).toLocaleTimeString(
                              navigator.language,
                              { hour: '2-digit', minute: '2-digit', hour12: true }
                          ),
                          date: 'New Message',
                      },
                  ];
                  this.manageDocIcon();
                  this.handelNewMessage.emit(
                      this.discussionMessages[this.discussionMessages?.length - 1]
                  );
                  // setTimeout(() => {
                  this.showLoaddingConversation = false;
                  // }, 500);
              }
          }
      );
  }

  subscribeChatRoomTyping() {
      this.discussionSocketService.subscribe(
          discussionSocketEvents.CHAT_ROOM_TYPING,
          (response: any) => {
              this.usersTyping = Object.values(response).filter(
                  (item: any) => item.user_id !== this.currentActiveUserId
              );
          }
      );
  }

  readMessages() {
     let chatroomData:any = {
        id: this.selectedChatRoomDetails._id,
     }
     if(this.selectedChatRoomDetails.type == DISCUSSION_COMMENT_TYPE.CHACKLIST || this.selectedChatRoomDetails.type == DISCUSSION_COMMENT_TYPE.TASK){
        chatroomData['type'] = this.selectedChatRoomDetails.type
     }
      this.discussionSocketService.emit(
          discussionSocketEvents.READ_MESSAGES,
          chatroomData,
          (response: any) => {
              if (response.success) {
                  this.discussionSocketService.readMessage.next({
                      id: this.selectedChatRoomDetails._id,
                  });
              }
          }
      );
  }

  checkScroll() {
      this.readMessages();
      this.discussionMessages = this.discussionMessages.map((item: any) => {
          if (item['date'] === 'New Message') {
              item['date'] = moment(item?.created_at).startOf('d');
          }
          return item;
      });
      this.manageDocIcon();
  }

  onScrollUp(callback?: any) {
      if (this.hasNextValue) {
          this.currentPage += 1;
          this.getMessageList(callback);
          this.checkScroll();
      }
  }

  handelScrollCharRoom() {
      this.hasBootomTouch = this.scrollFrame.nativeElement.scrollTop < 200;
      // const threshold = 150;
      // const position =
      //   this.scrollFrame.nativeElement.scrollTop +
      //   this.scrollFrame.nativeElement.offsetHeight;
      // const height = this.scrollFrame.nativeElement.scrollHeight;
      // this.hasBootomTouch = position > height - threshold;
  }

  scroll(id: string) {
      if (!document.getElementById(id)) {
          this.onScrollUp(() => {
              this.scroll(id);
          });
      } else {
          document
              .getElementById(id)
              .scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.scrollId = id;
          setTimeout(() => {
              this.scrollId = null;
          }, 1500);
      }
  }

  handelOnNativeScrollEvent(event: any) {}

  getMessageList(callback?: any) {
      const where: any = {
          id: this.selectedChatRoomDetails._id,
          page: this.currentPage,
          limit: this.currentCount,
      };
      if (
          this.dateRange?.controls['from_date']?.value &&
      this.dateRange?.controls['to_date']?.value
      ) {
          where.startDate = moment(this.dateRange.controls['from_date'].value)
              .startOf('d')
              .toISOString();
          where.endDate = moment(this.dateRange.controls['to_date'].value)
              .endOf('d')
              .toISOString();
      }
      this.showLoaddingConversation = true;

      if (
          DISCUSSION_COMMENT_TYPE.DISCUSSION !== this.selectedChatRoomDetails.type
      ) {
          where.type = this.selectedChatRoomDetails.type;
      }
      this.discussionSocketService.emit(
          discussionSocketEvents.GET_CHAT_LIST,
          where,
          (response: any) => {
              if (response.success) {
                  const data = response.data.map((item: any) => {
                      item.is_video = false;
                      // if (item.message) {
                      //   item.message = this.utilsService.parseHTMLManipulation(
                      //     item.message,
                      //     item.mention_user,
                      //     item.mention_group
                      //   );
                      // }
                      item.sender.profile_image =
              item.sender.profile_image == null ||
              item.sender.profile_image == undefined
                  ? '#3477dc'
                  : item.sender.profile_image;
                      item.sender.avatar = this.utilsService.forShortName(
                          item?.sender?.first_name + ' ' + item?.sender?.last_name
                      );

                      if (
                          /\.(MP4|mp4)$/.test(
                              item?.attachement_details[0]?.attachement_name
                          )
                      ) {
                          item.is_video = true;
                      }

                      return {
                          ...item,
                          time: new Date(item?.created_at).toLocaleTimeString(
                              navigator.language,
                              { hour: '2-digit', minute: '2-digit', hour12: true }
                          ),
                          date: moment(item?.created_at).startOf('d'),
                      };
                  });
                  this.hasNextValue = data.length > 0;
                  this.discussionMessages = data
                      .reverse()
                      .concat(this.discussionMessages);

                  this.discussionMessages = _.unionBy(this.discussionMessages, '_id');
                  this.manageDocIcon();
                  // if (this.currentPage === 1) {
                  //   this.hasNextValue =
                  //     this.scrollFrame.nativeElement.scrollHeight >
                  //     this.scrollFrame.nativeElement.clientHeight;

                  //   setTimeout(() => {
                  //     this.readMessages();
                  //   }, 10);
                  // }

                  if (
                      this.selectedChatRoomDetails?.unread_count &&
            this.selectedChatRoomDetails?.unread_count > 0
                  ) {
                      this.selectedChatRoomDetails.unread_count = 0;
                    }
                    this.readMessages();
                    this.showLoaddingConversation = false;

                  setTimeout(() => {
                      this.handelScrollCharRoom();
                      this.showLoaddingConversation = false;
                      if (data.length > 0 && !callback) {
                          if (data[data.length - 1]) {
                              if (document.getElementById(data[data.length - 1]?._id)) {
                                  document
                                      .getElementById(data[data.length - 1]?._id)
                                      .scrollIntoView({ block: 'end' });
                              }
                          }
                      }
                  }, 500);

                  if (callback) {
                      callback();
                  }
              }
          }
      );
  }

  scrollBottom() {
      this.chatRoomScroll.nativeElement.scrollTop = 0;
      // this.chatRoomScroll.nativeElement.scrollTop =
      //   this.chatRoomScroll.nativeElement.scrollHeight;
  }

  scrollBottomSmooth() {
      document.getElementById('msgContentBox').scrollTo({
          behavior: 'smooth',
          top: 0,
      });
  }

  async sendFiles(file: any) {
      const formData = new FormData();
      if (this.selectedChatRoomDetails.task_id) {
          formData.append('task_id', this.selectedChatRoomDetails.task_id);
      }
      if (this.selectedChatRoomDetails.project_id) {
          formData.append('project_id', this.selectedChatRoomDetails.project_id);
      }
      if (this.selectedChatRoomDetails.dms_id) {
          formData.append('dms_id', this.selectedChatRoomDetails.dms_id);
      }
      if (this.selectedChatRoomDetails.type) {
          formData.append('type', this.selectedChatRoomDetails.type);
      }
      if (this.selectedChatRoomDetails._id) {
          formData.append('id', this.selectedChatRoomDetails._id);
      }
      if (this.selectedChatRoomDetails.title) {
          formData.append('folder_name', this.selectedChatRoomDetails.title);
      }
      if (this.selectedChatRoomDetails.accesses) {
          const group_ids: any = [];
          const user_ids: any = [];
          this.selectedChatRoomDetails?.accesses?.forEach((item: any) => {
              if (item.group_id !== 0 && item.group_id) {
                  group_ids.push(item.group_id);
              } else if (item.user_id) {
                  user_ids.push(item.user_id);
              }
          });

          formData.append('group_ids', JSON.stringify(group_ids));
          formData.append('user_ids', JSON.stringify(user_ids));
      }

      
      let url:any = await this.fileUploadInS3Bucket([file]);
      url = await this.utilsService.setThumbPathAndUpdateFilePath(url);

      formData.append('attachments', JSON.stringify(url[0]));
      const { data }: any = await this.dmsService.uploadFiles(formData);

      return {
          _id: data?._id,
          thumb_path: data?.thumb_path,
          file_path: data?.file_path,
          type: data?.type,
          size: data?.size,
          name: data?.name,
      };
  }

  async addCommentData(e: any) {
      let msg_send = true;
      if (
          (e.comment && e.comment !== '') ||
      e?.updateFiles?.length ||
      e?.allFiles?.length ||
      e?.client_contact?.length
      ) {
          const request: any = {
              id: this.selectedChatRoomDetails._id,
              message: e.comment ? e.comment : '',
              message_type: MESSAGE_TYPES['TEXT'],
              title: this.selectedChatRoomDetails.title,
              type: this.selectedChatRoomDetails.type,
          };

          const files: any = [];
          const audioFiles = [];
          if (e?.updateFiles?.length) {
              request['message_type'] = MESSAGE_TYPES['DOCUMENT'];
              let index = 0;

              if (this.selectedChatRoomDetails._id) {
                  for (const item of [...e.updateFiles]) {
                      if (
                          !/\.(gif|jpe?g|tiff?jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(
                              item.name
                          )
                      ) {
                          this.uploadFileFakeData.push(item);
                      }
                  }

                  for (const item of [...e.updateFiles]) {
                      if (
                          !/\.(gif|jpe?g|tiff?jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(
                              item.name
                          )
                      ) {
                          const fileRequest: any = {
                              id: this.selectedChatRoomDetails._id,
                              message_type: MESSAGE_TYPES['DOCUMENT'],
                              type: this.selectedChatRoomDetails.type,
                          };

                          if (index + 1 === [...e.updateFiles].length) {
                            //   fileRequest.message = e.comment;
                              msg_send = false;
                          }

                          const data: any = await this.sendFiles(item);
                          fileRequest['uploadFile'] = [data];
                          this.saveMessage(fileRequest, () => {
                              this.uploadFileFakeData.shift();
                            });
                        msg_send = true;
                      } else {
                          const data: any = await this.sendFiles(item);
                          data['image_type'] = IMAGE_UPLOAD_TYPE.WEB
                          files.push(data);
                      }

                      index += 1;
                  }      
                  request['uploadFile'] = files;
              } else {
                  [...e.updateFiles].forEach(async (item, indexOffline) => {
                      if (
                          !/\.(gif|jpe?g|tiff?jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(
                              item.name
                          )
                      ) {
                          const fileRequest: any = {
                              id: this.selectedChatRoomDetails?._id,
                              message_type: MESSAGE_TYPES['DOCUMENT'],
                          };

                          if (indexOffline + 1 === [...e.updateFiles].length) {
                              fileRequest.message = e.comment;
                              msg_send = false;
                          }
                          fileRequest['uploadFile'] = [item];
                          fileRequest['uploadFileInBase64'] =
                await this.convertFilesToBase64([item]);
                          this.saveMessage(fileRequest);
                      } else {
                          files.push(item);
                      }
                  });
                  request['uploadFile'] = [...files];
                  request['uploadFileInBase64'] = await this.convertFilesToBase64([
                      ...files,
                  ]);
              }
          }

          if (e?.allFiles?.length) {
              if (this.selectedChatRoomDetails._id) {
                  const [file] = e.allFiles;

                  let arr = file?.result?.split(','),
                      bstr = atob(arr[arr.length - 1]),
                      n = bstr.length,
                      u8arr = new Uint8Array(n);
                  while (n--) {
                      u8arr[n] = bstr.charCodeAt(n);
                  }

                  const data: any = await this.sendFiles(
                      new File([u8arr], file?.files?.name, { type: file?.files?.type })
                  );

                  if (!request['uploadFile']) {
                      request['uploadFile'] = [];
                  }
                  request['uploadFile'].push(data);
                  request['message_type'] = MESSAGE_TYPES['AUDIO'];
              } else {
                  request['audioFiles'] = e.allFiles;
                  request['message_type'] = MESSAGE_TYPES['AUDIO'];
              }
          }

          if (this.replyMessage?._id) {
              request['parent_id'] = this.replyMessage?._id;
          }

          if (this.selectedChatRoomDetails.project_id) {
              request['project_id'] = this.selectedChatRoomDetails.project_id;
          }

          if (e?.mention_user?.length) {
              request['mention_user'] = e.mention_user;
          }
          if (e?.mention_group?.length) {
              request['mention_group'] = e.mention_group;
          }
          if (e?.client_contact?.length) {
              msg_send = false;
              request['message_type'] = MESSAGE_TYPES['INFO'];
              e.client_contact.forEach((element: any) => {
                  request['client_contact'] = element;
                  request.comment = element.comment ? element.comment : '';
                  this.saveMessage(request);
              });
          }
          if (msg_send) {
              if ((request?.message && request?.message!== '') || request?.uploadFile?.length || (request?.mention_group?.length && request?.uploadFile?.length===0) || (request?.mention_user?.length && request?.uploadFile?.length===0)){
                this.saveMessage(request);
            }
          }
      }
  }
  async saveMessage(objMessage: any, callback?: any) {
      if (this.selectedChatRoomDetails._id) {
          this.discussionSocketService.emit(
              discussionSocketEvents.MESSAGE,
              objMessage,
              (response: any) => {
                  if (response.success) {
                      this.selectedMessage = {};
                      this.replyMessage = null;
                      // if (response.data.message) {
                      //   response.data.message = this.utilsService.parseHTMLManipulation(
                      //     response.data.message,
                      //     response.data.mention_user,
                      //     response.data.mention_group
                      //   );
                      // }
                      // setTimeout(() => {

                      this.discussionMessages = [
                          ...this.discussionMessages,
                          {
                              ...response.data,
                              time: new Date(response?.data?.created_at).toLocaleTimeString(
                                  navigator.language,
                                  { hour: '2-digit', minute: '2-digit', hour12: true }
                              ),
                              date: moment(response?.data?.created_at).startOf('d'),
                          },
                      ];

                      this.discussionMessages = this.discussionMessages.map(
                          (item: any) => {
                              return {
                                  ...item,
                                  date: moment(item?.created_at).startOf('d'),
                              };
                          }
                      );
                      this.ch.detectChanges();
                      this.manageDocIcon();
                      this.handelNewMessage.emit(
                          this.discussionMessages[this.discussionMessages?.length - 1]
                      );
                      // setTimeout(() => this.scrollBottom(), 800);
                      this.dropFiles = [];

                      setTimeout(() => {
                          this.showLoaddingConversation = false;
                      }, 500);

                      this.readMessages()
                      if (callback) {
                          callback();
                      }
                      // }, 10000);
                  }
              }
          );
      } else {
          objMessage._id = this.currentIndex++;
          if (objMessage?.client_contact) {
              objMessage.clientInfo = this.clientListData[objMessage?.client_contact];
          }
          if (objMessage?.parent_id) {
              objMessage.parent = this.discussionMessages.find(
                  (ele: any) => ele._id === objMessage?.parent_id
              );
          }
          if (objMessage?.parent?.client_contact) {
              objMessage.parent.clientInfo =
          this.clientListData[objMessage?.parent?.client_contact];
          }

          this.discussionMessages.push({
              ...objMessage,
              sender: { user_id: this.currentActiveUserId },
              date: moment(new Date()).startOf('d'),
              created_at: new Date(),
              time: new Date().toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
              }),
          });
          this.manageOfflineDocIcon();
          this.discussionMessages = [...this.discussionMessages];
          this.replyMessage = null;
          setTimeout(async () => {
              this.discussionService.offlineTaskCommentArr.next({
                  message: await this.convertTextMsgArray(this.discussionMessages),
              });
              await this.localStorage.setDataInIndexedDB(
                  'tasklocalcomment',
                  this.discussionMessages
              );
          }, 100);
      }
  }

  convertFilesToBase64(files: File[]): Promise<string[]> {
      return new Promise<string[]>((resolve, reject) => {
          const base64Strings: string[] = [];
          let counter = 0;
          files.forEach((file) => {
              const reader = new FileReader();
              reader.onload = () => {
                  const base64String = reader.result as string;
                  base64Strings.push(base64String);
                  counter++;
                  if (counter === files.length) {
                      resolve(base64Strings);
                  }
              };

              reader.onerror = () => {
                  reject(reader.error);
              };

              reader.readAsDataURL(file);
          });
      });
  }

  convertTextMsgArray(arr: any[]) {
      const tempArr: any = [];
      arr.forEach((item) => {
          const msgObj = {
              comment_id: item._id,
              message: item.message,
              message_type: item.message_type,
              type: 'TASK',
              client_contact: item.client_contact ? item.client_contact : '',
              uploadFile: item.uploadFile,
              audioFiles: item.audioFiles,
              parent_id: item.parent_id,
          };
          tempArr.push(msgObj);
      });
      return tempArr;
  }

  userTypingData(e: any) {
      if (this.selectedChatRoomDetails._id) {
          this.discussionSocketService.emit(discussionSocketEvents.TYPING, {
              id: this.selectedChatRoomDetails._id,
              message: e,
          });
      }
  }

  openDetails() {
      this.openDetailWindow.emit();
  }

  async openPreviewImage(selectdImageId: any) {
      let allImageMedia: any = [];
      (
          await this.discussionService.discussionImage({
              type: this.selectedChatRoomDetails.type,
              _id: this.selectedChatRoomDetails._id,
          })
      ).subscribe((resProjectList: any) => {
          allImageMedia = resProjectList;
      });

      let imageIndex: number;
      const imageArray: any = [];
      allImageMedia.forEach((element: any, index: number) => {
          if (element.attachment_id == selectdImageId) {
              imageIndex = index;
          }
          imageArray.push(element.attachement_url);
      });
      this.imagePreviewComponent = this.matDialog.open(ImagePreviewComponent, {
          disableClose: true,
          panelClass: ['custom-modal', 'custom-modal-md', 'open-preview-cls'],
          data: { imageArray: imageArray, imageIndex: imageIndex },
      });
      this.imagePreviewComponent.afterClosed().subscribe(async (res) => {
          if (res) {
          }
      });
  }

  isReplyMessage(msg: any) {
      this.replyMessage = msg;
  }

  copyMessage(message: any) {
      this.clipboard.copy(message?.message.replace(/<[^>]*>/g, ''));  // NOSONAR
  }

  deleteMessage(message: any) {
      return (new Date().getTime() - new Date(message?.created_at).getTime()) /
      1000 /
      60 >
      15
          ? false
          : true;
  }

  removeMessage(message: any) {
      this.removedMessage = message;
      if (this.removedMessage) {
          if (this.selectedChatRoomDetails._id) {
              this.discussionSocketService.emit(
                  discussionSocketEvents.DELETE_MESSAGE,
                  {
                      id: this.removedMessage._id,
                      type: this.selectedChatRoomDetails.type,
                      discussion_id: this.selectedChatRoomDetails._id,
                  },
                  (response: any) => {
                      if (response.success) {
                          this.discussionMessages = this.discussionMessages.filter(
                              (item: any) => item?._id !== this.removedMessage._id
                          );
                          this.removedMessage = null;
                          this.handelNewMessage.emit(
                              this.discussionMessages[this.discussionMessages.length - 1]
                          );
                      }
                  }
              );
          } else {
              this.discussionMessages = this.discussionMessages.filter(
                  (item: any) => item?._id !== this.removedMessage._id
              );
              const index = this.discussionMessages.findIndex(
                  (message) => message._id === this.removedMessage._id
              );
              if (index > -1) {
                  this.discussionMessages.splice(index, 1);
              }
              this.removedMessage = null;
              setTimeout(async () => {
                  this.discussionService.offlineTaskCommentArr.next({
                      message: await this.convertTextMsgArray(this.discussionMessages),
                  });
                  await this.localStorage.setDataInIndexedDB(
                      'tasklocalcomment',
                      this.discussionMessages
                  );
              }, 100);
          }
      }
  }

  subscribeRemoveMessageChatRoom() {
      this.discussionSocketService.subscribe(
          discussionSocketEvents.CHAT_ROOM_DELETE_MESSAGE,
          async (response: any) => {
              if (response) {
                  this.discussionMessages = this.discussionMessages.filter(
                      (item: any) => item?._id !== response.id
                  );
              }
          }
      );
  }

  handelResetSearch() {
      this.searchResponse['count'] = 0;
      this.searchResponse['data'] = [];
      this.searchNextStack = this.searchResponse['data'];
      this.searchPreviousStack = [];
      this.highlightMessage = null;
      this.search = null;
  }

  async handelSearchMessage() {
      this.searchResponse['count'] = 0;
      this.searchResponse['data'] = [];
      this.searchNextStack = this.searchResponse['data'];
      this.searchPreviousStack = [];
      this.highlightMessage = null;

      if (this.search && this.search !== '' && this.selectedChatRoomDetails._id) {
          this.discussionSocketService.emit(
              discussionSocketEvents.SEARCH_MESSAGES,
              {
                  id: this.selectedChatRoomDetails._id,
                  type: this.selectedChatRoomDetails.type,
                  search: this.search,
              },
              (response: any) => {
                  if (response.success) {
                      this.searchResponse['count'] = response?.data?.count;
                      this.searchResponse['data'] = response?.data?.data.map(
                          (item: any) => item?._id
                      );
                      this.searchNextStack = this.searchResponse['data'];
                      this.searchPreviousStack = [];
                      this.searchDown();
                  }
              }
          );
      } else if (
          !this.selectedChatRoomDetails._id &&
      this.search &&
      this.search !== ''
      ) {
          this.searchNextStack = await this.discussionMessages
              .filter((ele: any) => ele.message.toLowerCase().includes(this.search))
              .map((item: any) => item?._id);
          this.searchResponse['count'] = this.searchNextStack?.length;
          this.searchPreviousStack = [];
      }
  }

  searchUp() {
      if (this.searchPreviousStack.length) {
          this.searchNextStack.push(
              this.searchPreviousStack[this.searchPreviousStack.length - 1]
          );
          this.scroll(
              this.searchPreviousStack[this.searchPreviousStack.length - 1]
          );
          this.highlightMessage =
        this.searchPreviousStack[this.searchPreviousStack.length - 1];
          this.searchPreviousStack.pop();
      } else {
          this.searchPreviousStack = this.searchNextStack.reverse();
          this.searchNextStack = [];
          this.searchDown();
      }
  }

  searchDown() {
      if (this.searchNextStack.length) {
          this.searchPreviousStack.push(
              this.searchNextStack[this.searchNextStack?.length - 1]
          );
          this.scroll(this.searchNextStack[this.searchNextStack?.length - 1]);
          this.highlightMessage =
        this.searchNextStack[this.searchNextStack?.length - 1];
          this.searchNextStack?.pop();
      } else {
          this.searchNextStack = this.searchPreviousStack?.reverse();
          this.searchPreviousStack = [];
          this.searchDown();
      }
  }
  openContactDetails(client_id: any) {
      this.ClientInfoPreviewComponent = this.matDialog.open(
          ClientInfoPreviewComponent,
          {
              disableClose: true,
              panelClass: [
                  'custom-modal',
                  'custom-modal-md',
                  'new-client-info-cls-add',
              ],
              data: { clientId: client_id },
          }
      );
      this.ClientInfoPreviewComponent.afterClosed().subscribe(async (res) => {
          if (res) {
          }
      });
  }

  manageDocIcon() {
      if (this.selectedChatRoomDetails._id) {
          this.discussionMessages.forEach((element) => {
              element.attachement_details.forEach(async (item: any) => {
                  item.is_image = false;
                  element.is_image = false;
                  if (
                      /\.(gif|jpe?g|tiff?jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(
                          item?.attachement_type
                      ) ||
            item.attachement_type === 'img'
                  ) {
                      item.is_image = true;
                      element.is_image = true;
                  }
                  if (item.attachement_type) {
                      item.image = await this.utilsService.getDocumentIcon(
                          { type: item.attachement_type, file_path: item?.attachement_url },
                          false
                      );
                  }

                  if (
                      item.attachement_type === 'doc' &&
            item.attachement_thumbnail ===
              'https://app.taskopad.com/uploads/null'
                  ) {
                      const lastDotIndex = item?.attachement_url.lastIndexOf('.');
                      if (lastDotIndex !== -1) {
                          const stringAfterLastDot =
                item?.attachement_url.slice(lastDotIndex);
                          item.image = await this.utilsService.getDocumentIcon(
                              { type: stringAfterLastDot, file_path: item?.attachement_url },
                              false
                          );
                      }
                  }
              });

              if (element?.parent) {
                  element?.parent?.attachement_details?.forEach(async (parent: any) => {
                      parent.is_image = false;
                      element.parent.is_image = false;
                      if (
                          /\.(gif|jpe?g|tiff?jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(
                              parent?.attachement_type
                          )
                      ) {
                          parent.is_image = true;
                          element.parent.is_image = true;
                      }

                      if (parent?.attachement_type) {
                          parent.image = await this.utilsService.getDocumentIconTemporary(
                              {
                                  type: parent?.attachement_type,
                                  file_path: parent?.attachement_url,
                              },
                              false
                          );
                      }
                  });
              }

              if (element?.client_contact?.length) {
                  element.clientInfo = this.clientListData[element?.client_contact[0]];
              }
              if (element?.parent?.client_contact?.length) {
                  element.parent.clientInfo =
            this.clientListData[element?.parent?.client_contact[0]];
              }
          });
      }
  }

  manageOfflineDocIcon() {
      const allowedImageFormats = [
          'gif',
          'jpeg',
          'png',
          'gif',
          'JPG',
          'JPEG',
          'PNG',
          'GIF',
      ];
      if (!this.selectedChatRoomDetails._id) {
          this.discussionMessages.forEach((element) => {
              if (element?.uploadFile) {
                  element.message = element.message ? element.message : '';
                  element?.uploadFile.forEach(async (item: any) => {
                      item.is_image = false;
                      element.is_image = false;

                      if (
                          allowedImageFormats.some((format) => item.type.includes(format))
                      ) {
                          item.is_image = true;
                          element.is_image = true;
                      }
                      if (item.type) {
                          item.image = await this.utilsService.getDocumentIcon(
                              { type: item.type, file_path: item?.attachement_url },
                              false
                          );
                      }

                      if (item.parent) {
                          if (item.parent.uploadFile) {
                              item.parent.uploadFile.forEach(async (parent: any) => {
                                  parent.is_image = false;

                                  if (
                                      allowedImageFormats.some((format) =>
                                          item.type.includes(format)
                                      )
                                  ) {
                                      parent.is_image = true;
                                      element.is_image = true;
                                  }

                                  if (item.type) {
                                      parent.image = await this.utilsService.getDocumentIcon(
                                          {
                                              type: parent.type,
                                              file_path: item?.attachement_url,
                                          },
                                          false
                                      );
                                  }
                              });
                          }
                      }
                  });
              }
          });
      }
  }

  uploadFiles(file: any) {
      this.dropFiles = [...file];
  }

  setOrignalMessage(item: any) {
      if (item?.message.indexOf('data-type="cuser"') > -1) {
          this.selectedChatRoomDetails?.accesses?.filter((item: any) => {
              item?.message.element.user_id;
          });
      }
      if (item?.message.indexOf('data-type="group"') > -1) {
          this.selectedChatRoomDetails?.accesses?.filter((item: any) => {});
      }
  }

  redirectMap(lat: any, lon: any) {
      window.open(`https://www.google.com/maps/dir/${lat},+${lon}`, '_blank');
  }

  async getclientList() {
      // (await this.taskService.getClientList(this.selectedCompanyId)).subscribe(
      //   async (res: any) => {
      const client = this.taskState?.client_details;
      if (client) {
          this.clientListData = _.keyBy(client, 'id');
      }
      // }
      // );
  }
  dateRangeFormInit() {
      this.dateRange = this.formBuilder.group({
          from_date: ['', [Validators.required]],
          to_date: ['', [Validators.required]],
      });
  }

  async removeRange() {
      this.currentPage = 1;
      this.discussionMessages = [];
      this.dateRanges = null;
      this.dateRange.reset();
      this.getMessageList();
  }

  async onDateRangeChange() {
      this.currentPage = 1;
      this.discussionMessages = [];
      this.getMessageList();
      // let startDate = this.dateRange.controls['from_date'].value ? (this.dateRange.controls['from_date'].value).getTime() : '';
      // let endDate = this.dateRange.controls['to_date'].value ? (this.dateRange.controls['to_date'].value).getTime() : '';
  }

  downloadFile(file: any) {
      const link = document.createElement('a');
      link.setAttribute('download', file.attachement_name);
      link.href = file.attachement_url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
  }

  onPlay(index: number) {
      if (
          this.currentlyPlayingIndex !== -1 &&
      this.currentlyPlayingIndex !== index
      ) {
          const audioElements = document.getElementsByTagName('audio');
          if (audioElements[this.currentlyPlayingIndex]) {
              audioElements[this.currentlyPlayingIndex].pause();
          }
      }
      this.currentlyPlayingIndex = index;
  }

  async rangeTaskDueDate(selectedDate: Date) {
      const selection = this.selectionModel.selection,
          dateRange = this.selectionStrategy.selectionFinished(
              selectedDate,
              selection
          );

      this.selectionModel.updateSelection(dateRange, this);
      this.dateRanges = new DateRange<Date>(dateRange.start, dateRange.end);
      if (this.selectionModel.isComplete()) {
          this.dateRange.controls['from_date'].setValue(
              dateRange.start.setHours(0, 0, 0, 0)
          );
          this.dateRange.controls['to_date'].setValue(
              dateRange.end.setHours(23, 59, 59)
          );
          this.startDate = dateRange.start.setHours(0, 0, 0, 0);
          this.endDate = dateRange.end.setHours(23, 59, 59);
      }
  }
  focusElement() {
      setTimeout(() => {
          this.myInput?.nativeElement?.focus();
      });
  }

  closeMenu(menu:any){
    menu.closeMenu();
  }

  async fileUploadInS3Bucket(fileArr: any): Promise<string[]> {
        let finalURL: any[] = [];
        const fileArrURL = fileArr.map((fileData: any) => ({
            folderName: "/uploads",
            fileName: fileData.name,
            contentType: '.'+fileData.name.split('.')[1]
        }));

        try {
            const observableRes = await this.userDetailService.getPresignURL(fileArrURL);
            const res = await lastValueFrom(observableRes);

            await Promise.all(
                fileArr.map(async (fileData: any, index: number) => {
                    await this.userDetailService.s3MediaUpload(res?.urls[index]?.url, fileData);

                    finalURL.push(
                        {
                            data_path: res?.urls[index]?.url?.replace(environment.MEDIA_BUCKET_NAME, environment.DOMAIN).split('?')[0],
                            size: fileArr[index]?.size,
                            name: fileArr[index]?.name,
                            type: '.'+fileArr[index]?.name?.split('.')[1]
                        }
                    )
                })
            );
            return finalURL;
        } catch (error) {
            console.error("Error in file upload:", error);
            return [];
        }
    }
}
