import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { DateFormatPipe } from './pipe/date-format.pipe';
import { TitleCaseDirective } from './directive/title-case.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BulkImportComponent } from './components/bulk-import/bulk-import.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AppCapitalizeFirstSpaceDirective } from './directive/app-capitalize-first-space.directive';
import { HighlightPipe } from './pipe/highlight.pipe';
import { NumberDirective } from './directive/numbers-only.directive';
import { GuideModelComponent } from './components/guide-model/guide-model.component';
import { FilterPipe } from './pipe/filter.pipe';
import { CheckBoxCommonComponent } from './components/check-box-common/check-box-common.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GroupAssigneeCommonCheckboxComponent } from './components/group-assignee-common-checkbox/group-assignee-common-checkbox.component';
import { CustomFiledComponent } from './components/custom-filed/custom-filed.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { TaskDueDateCommonComponent } from './components/task-due-date-common/task-due-date-common.component';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DocumentIconComponent } from './components/document-icon/document-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DecimalOnlyDirective } from './directive/decimal-only.directive';
import { CustomizeFieldTaskClientComponent } from './components/customize-field-task-client/customize-field-task-client.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { TaskReminderComponentComponent } from './components/task-reminder-component/task-reminder-component.component';
import { MatChipPreviewComponent } from './components/mat-chip-preview/mat-chip-preview.component';
import { ClientInfoPreviewComponent } from './components/client-info-preview/client-info-preview.component';
import { CommentEditorComponent } from './components/comment-editor/comment-editor.component';
import { QuillModule } from 'ngx-quill';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { DropZoneDirective } from './directive/drop-zone.directive';
import { FileSizePipe } from './pipe/file-size.pipe';
import { ManuBarComponent } from './components/manu-bar/manu-bar.component';
const lang = 'en-GB';
import { UserStatePipe } from './pipe/user-state.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MessageComponent } from './components/message/message.component';
import { ReplayMessageComponent } from './components/replay-message/replay-message.component';
import { GroupByPipe } from './pipe/group-by.pipe';
import { CustomDatePipe } from './pipe/custom-date.pipe';
import { AddTimeLogComponent } from './components/add-time-log/add-time-log.component';
import { HighlighterPipe } from './pipe/highlighter.pipe';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { UserStateByUseridPipe } from './pipe/user-state-by-userid.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { NotificationPreviewComponent } from './components/notification-preview/notification-preview.component';
import { ClientMenuComponent } from './client-menu/client-menu.component';
import { ChecklistCommentComponent } from './components/checklist-comment/checklist-comment.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { HourFormatPipe } from './pipe/hour-format.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { HoursSecondFormatPipe } from './pipe/hours-second-format.pipe';
import { MemberHoverComponent } from './components/member-hover/member-hover.component';
import { UserLastSeenPipePipe } from './pipe/user-last-seen-pipe.pipe';
import { GroupHoverComponent } from './components/group-hover/group-hover.component';
import { ExportReportsComponent } from './components/export-reports/export-reports.component';
import { BarChartComponent } from './components/reports/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/reports/pie-chart/pie-chart.component';
import { LineChartComponent } from './components/reports/line-chart/line-chart.component';
import { ChartCardComponent } from './components/reports/chart-card/chart-card.component';
import { ReportHeaderComponent } from './components/reports/report-header/report-header.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatTabsModule } from '@angular/material/tabs';
import { SaveReportComponent } from './components/reports/save-report/save-report.component';
import { ActivityBarChartComponent } from './components/reports/activity-bar-chart/activity-bar-chart.component';
import { ActivityPieChartComponent } from './components/reports/activity-pie-chart/activity-pie-chart.component';
import { FilterSearchPipe } from './pipe/filter-search.pipe';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { MatCardModule } from '@angular/material/card';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { HighlightDirective } from './directive/highlight.directive';
import { SharedClientComponent } from './components/shared-client/shared-client.component';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
@NgModule({
    declarations: [
        DateFormatPipe,
        TitleCaseDirective,
        BulkImportComponent,
        ConfirmDialogComponent,
        AppCapitalizeFirstSpaceDirective,
        HighlightPipe,
        NumberDirective,
        GuideModelComponent,
        FilterPipe,
        DecimalOnlyDirective,
        CheckBoxCommonComponent,
        GroupAssigneeCommonCheckboxComponent,
        CustomFiledComponent,
        TaskDueDateCommonComponent,
        DocumentIconComponent,
        CustomizeFieldTaskClientComponent,
        TaskReminderComponentComponent,
        MatChipPreviewComponent,
        ClientInfoPreviewComponent,
        CommentEditorComponent,
        DropZoneDirective,
        FileSizePipe,
        ManuBarComponent,
        UserStatePipe,
        MessageComponent,
        ReplayMessageComponent,
        GroupByPipe,
        CustomDatePipe,
        HighlighterPipe,
        ImagePreviewComponent,
        UserStateByUseridPipe,
        SafeHtmlPipe,
        NotificationPreviewComponent,
        ClientMenuComponent,
        AddTimeLogComponent,
        ChecklistCommentComponent,
        DocumentPreviewComponent,
        HourFormatPipe,
        HoursSecondFormatPipe,
        UserLastSeenPipePipe,
        MemberHoverComponent,
        GroupHoverComponent,
        ExportReportsComponent,
        BarChartComponent,
        PieChartComponent,
        LineChartComponent,
        ChartCardComponent,
        ReportHeaderComponent,
        SaveReportComponent,
        ActivityBarChartComponent,
        ActivityPieChartComponent,
        FilterSearchPipe,
        SafeUrlPipe,
        HighlightDirective,
        SharedClientComponent,
        MaintenancePageComponent,
    ],
    imports: [
        CommonModule,
        // BrowserAnimationsModule,
        SharedRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatMenuModule,
        MatButtonModule,
        MatInputModule,
        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatChipsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatIconModule,
        NgxMatTimepickerModule.setLocale(lang),
        MatRippleModule,
        MatAutocompleteModule,
        MatSidenavModule,
        DragDropModule,
        NgxMatIntlTelInputComponent,
        QuillModule,
        NgxTrimDirectiveModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatExpansionModule,
        NgChartsModule,
        NgxDaterangepickerMd.forRoot(),
        MatTabsModule,
        MatCardModule,
        NgxSlickJsModule.forRoot({
            links: {
                jquery: 'https://code.jquery.com/jquery-3.4.0.min.js',
                slickJs:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
                slickCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
                slickThemeCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
            },
        }),
        NgxDocViewerModule,
    ],
    exports: [
        DateFormatPipe,
        BulkImportComponent,
        AppCapitalizeFirstSpaceDirective,
        TitleCaseDirective,
        HighlightPipe,
        NumberDirective,
        GuideModelComponent,
        FilterPipe,
        CustomFiledComponent,
        DecimalOnlyDirective,
        MatCheckboxModule,
        FormsModule,
        GroupAssigneeCommonCheckboxComponent,
        CheckBoxCommonComponent,
        DocumentIconComponent,
        TaskDueDateCommonComponent,
        CustomizeFieldTaskClientComponent,
        TaskReminderComponentComponent,
        MatChipPreviewComponent,
        ClientInfoPreviewComponent,
        CommentEditorComponent,
        DropZoneDirective,
        FileSizePipe,
        UserStatePipe,
        MessageComponent,
        ReplayMessageComponent,
        GroupByPipe,
        CustomDatePipe,
        HighlighterPipe,
        UserStateByUseridPipe,
        SafeHtmlPipe,
        NotificationPreviewComponent,
        HourFormatPipe,
        MatExpansionModule,
        HoursSecondFormatPipe,
        UserLastSeenPipePipe,
        MemberHoverComponent,
        UserLastSeenPipePipe,
        GroupHoverComponent,
        HoursSecondFormatPipe,
        BarChartComponent,
        PieChartComponent,
        LineChartComponent,
        ChartCardComponent,
        ReportHeaderComponent,
        ActivityBarChartComponent,
        ActivityPieChartComponent,
        FilterSearchPipe,
        NgxDocViewerModule,
        HighlightDirective,
        SharedClientComponent
    ],
    providers: [DatePipe, { provide: LOCALE_ID, useValue: lang }],
})
export class SharedModule {}