export const COUNTRY_ISD_CODE = [
    {
        "id": 9,
        "name": "Antarctica",
        "iso3": "ATA",
        "phonecode": null,
        "currency": null,
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 77,
        "name": "French Southern Territories",
        "iso3": "ATF",
        "phonecode": null,
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 95,
        "name": "Heard Island and McDonald Islands",
        "iso3": "HMD",
        "phonecode": null,
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 21,
        "name": "Belarus",
        "iso3": "BLR",
        "phonecode": "375",
        "currency": "BYN",
        "currency_symbol": "Br",
        "emoji": "??"
    },
    {
        "id": 23,
        "name": "Belize",
        "iso3": "BLZ",
        "phonecode": "501",
        "currency": "BZD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 58,
        "name": "Czech Republic",
        "iso3": "CZE",
        "phonecode": "420",
        "currency": "CZK",
        "currency_symbol": "Kč",
        "emoji": "??"
    },
    {
        "id": 88,
        "name": "Guam",
        "iso3": "GUM",
        "phonecode": "+1-671",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 120,
        "name": "Lebanon",
        "iso3": "LBN",
        "phonecode": "961",
        "currency": "LBP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 4,
        "name": "Algeria",
        "iso3": "DZA",
        "phonecode": "213",
        "currency": "DZD",
        "currency_symbol": "دج",
        "emoji": "??"
    },
    {
        "id": 19,
        "name": "Bangladesh",
        "iso3": "BGD",
        "phonecode": "880",
        "currency": "BDT",
        "currency_symbol": "৳",
        "emoji": "??"
    },
    {
        "id": 49,
        "name": "Comoros",
        "iso3": "COM",
        "phonecode": "269",
        "currency": "KMF",
        "currency_symbol": "CF",
        "emoji": "??"
    },
    {
        "id": 91,
        "name": "Guinea",
        "iso3": "GIN",
        "phonecode": "224",
        "currency": "GNF",
        "currency_symbol": "FG",
        "emoji": "??"
    },
    {
        "id": 100,
        "name": "India",
        "iso3": "IND",
        "phonecode": "91",
        "currency": "INR",
        "currency_symbol": "₹",
        "emoji": "??"
    },
    {
        "id": 103,
        "name": "Iraq",
        "iso3": "IRQ",
        "phonecode": "964",
        "currency": "IQD",
        "currency_symbol": "د.ع",
        "emoji": "??"
    },
    {
        "id": 124,
        "name": "Liechtenstein",
        "iso3": "LIE",
        "phonecode": "423",
        "currency": "CHF",
        "currency_symbol": "CHf",
        "emoji": "??"
    },
    {
        "id": 7,
        "name": "Angola",
        "iso3": "AGO",
        "phonecode": "244",
        "currency": "AOA",
        "currency_symbol": "Kz",
        "emoji": "??"
    },
    {
        "id": 22,
        "name": "Belgium",
        "iso3": "BEL",
        "phonecode": "32",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 27,
        "name": "Bolivia",
        "iso3": "BOL",
        "phonecode": "591",
        "currency": "BOB",
        "currency_symbol": "Bs.",
        "emoji": "??"
    },
    {
        "id": 43,
        "name": "Chad",
        "iso3": "TCD",
        "phonecode": "235",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "emoji": "??"
    },
    {
        "id": 123,
        "name": "Libya",
        "iso3": "LBY",
        "phonecode": "218",
        "currency": "LYD",
        "currency_symbol": "د.ل",
        "emoji": "??"
    },
    {
        "id": 17,
        "name": "Bahamas The",
        "iso3": "BHS",
        "phonecode": "+1-242",
        "currency": "BSD",
        "currency_symbol": "B$",
        "emoji": "??"
    },
    {
        "id": 60,
        "name": "Djibouti",
        "iso3": "DJI",
        "phonecode": "253",
        "currency": "DJF",
        "currency_symbol": "Fdj",
        "emoji": "??"
    },
    {
        "id": 76,
        "name": "French Polynesia",
        "iso3": "PYF",
        "phonecode": "689",
        "currency": "XPF",
        "currency_symbol": "₣",
        "emoji": "??"
    },
    {
        "id": 102,
        "name": "Iran",
        "iso3": "IRN",
        "phonecode": "98",
        "currency": "IRR",
        "currency_symbol": "﷼",
        "emoji": "??"
    },
    {
        "id": 110,
        "name": "Jordan",
        "iso3": "JOR",
        "phonecode": "962",
        "currency": "JOD",
        "currency_symbol": "ا.د",
        "emoji": "??"
    },
    {
        "id": 119,
        "name": "Latvia",
        "iso3": "LVA",
        "phonecode": "371",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 39,
        "name": "Canada",
        "iso3": "CAN",
        "phonecode": "1",
        "currency": "CAD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 46,
        "name": "Christmas Island",
        "iso3": "CXR",
        "phonecode": "61",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 114,
        "name": "Korea North",
        "iso3": "PRK",
        "phonecode": "850",
        "currency": "KPW",
        "currency_symbol": "₩",
        "emoji": "??"
    },
    {
        "id": 29,
        "name": "Botswana",
        "iso3": "BWA",
        "phonecode": "267",
        "currency": "BWP",
        "currency_symbol": "P",
        "emoji": "??"
    },
    {
        "id": 64,
        "name": "Egypt",
        "iso3": "EGY",
        "phonecode": "20",
        "currency": "EGP",
        "currency_symbol": "ج.م",
        "emoji": "??"
    },
    {
        "id": 48,
        "name": "Colombia",
        "iso3": "COL",
        "phonecode": "57",
        "currency": "COP",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 62,
        "name": "East Timor",
        "iso3": "TLS",
        "phonecode": "670",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 93,
        "name": "Guyana",
        "iso3": "GUY",
        "phonecode": "592",
        "currency": "GYD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 6,
        "name": "Andorra",
        "iso3": "AND",
        "phonecode": "376",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 12,
        "name": "Armenia",
        "iso3": "ARM",
        "phonecode": "374",
        "currency": "AMD",
        "currency_symbol": "֏",
        "emoji": "??"
    },
    {
        "id": 52,
        "name": "Cook Islands",
        "iso3": "COK",
        "phonecode": "682",
        "currency": "NZD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 96,
        "name": "Honduras",
        "iso3": "HND",
        "phonecode": "504",
        "currency": "HNL",
        "currency_symbol": "L",
        "emoji": "??"
    },
    {
        "id": 101,
        "name": "Indonesia",
        "iso3": "IDN",
        "phonecode": "62",
        "currency": "IDR",
        "currency_symbol": "Rp",
        "emoji": "??"
    },
    {
        "id": 117,
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "phonecode": "996",
        "currency": "KGS",
        "currency_symbol": "лв",
        "emoji": "??"
    },
    {
        "id": 36,
        "name": "Burundi",
        "iso3": "BDI",
        "phonecode": "257",
        "currency": "BIF",
        "currency_symbol": "FBu",
        "emoji": "??"
    },
    {
        "id": 122,
        "name": "Liberia",
        "iso3": "LBR",
        "phonecode": "231",
        "currency": "LRD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 41,
        "name": "Cayman Islands",
        "iso3": "CYM",
        "phonecode": "+1-345",
        "currency": "KYD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 44,
        "name": "Chile",
        "iso3": "CHL",
        "phonecode": "56",
        "currency": "CLP",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 86,
        "name": "Grenada",
        "iso3": "GRD",
        "phonecode": "+1-473",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 98,
        "name": "Hungary",
        "iso3": "HUN",
        "phonecode": "36",
        "currency": "HUF",
        "currency_symbol": "Ft",
        "emoji": "??"
    },
    {
        "id": 99,
        "name": "Iceland",
        "iso3": "ISL",
        "phonecode": "354",
        "currency": "ISK",
        "currency_symbol": "kr",
        "emoji": "??"
    },
    {
        "id": 53,
        "name": "Costa Rica",
        "iso3": "CRI",
        "phonecode": "506",
        "currency": "CRC",
        "currency_symbol": "₡",
        "emoji": "??"
    },
    {
        "id": 57,
        "name": "Cyprus",
        "iso3": "CYP",
        "phonecode": "357",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 70,
        "name": "Falkland Islands",
        "iso3": "FLK",
        "phonecode": "500",
        "currency": "FKP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 87,
        "name": "Guadeloupe",
        "iso3": "GLP",
        "phonecode": "590",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 126,
        "name": "Luxembourg",
        "iso3": "LUX",
        "phonecode": "352",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 1,
        "name": "Afghanistan",
        "iso3": "AFG",
        "phonecode": "93",
        "currency": "AFN",
        "currency_symbol": "؋",
        "emoji": "??"
    },
    {
        "id": 35,
        "name": "Burkina Faso",
        "iso3": "BFA",
        "phonecode": "226",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 69,
        "name": "Ethiopia",
        "iso3": "ETH",
        "phonecode": "251",
        "currency": "ETB",
        "currency_symbol": "Nkf",
        "emoji": "??"
    },
    {
        "id": 73,
        "name": "Finland",
        "iso3": "FIN",
        "phonecode": "358",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 78,
        "name": "Gabon",
        "iso3": "GAB",
        "phonecode": "241",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "emoji": "??"
    },
    {
        "id": 84,
        "name": "Greece",
        "iso3": "GRC",
        "phonecode": "30",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 112,
        "name": "Kenya",
        "iso3": "KEN",
        "phonecode": "254",
        "currency": "KES",
        "currency_symbol": "KSh",
        "emoji": "??"
    },
    {
        "id": 125,
        "name": "Lithuania",
        "iso3": "LTU",
        "phonecode": "370",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 3,
        "name": "Albania",
        "iso3": "ALB",
        "phonecode": "355",
        "currency": "ALL",
        "currency_symbol": "Lek",
        "emoji": "??"
    },
    {
        "id": 55,
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "phonecode": "385",
        "currency": "HRK",
        "currency_symbol": "kn",
        "emoji": "??"
    },
    {
        "id": 72,
        "name": "Fiji Islands",
        "iso3": "FJI",
        "phonecode": "679",
        "currency": "FJD",
        "currency_symbol": "FJ$",
        "emoji": "??"
    },
    {
        "id": 113,
        "name": "Kiribati",
        "iso3": "KIR",
        "phonecode": "686",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 8,
        "name": "Anguilla",
        "iso3": "AIA",
        "phonecode": "+1-264",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 10,
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "phonecode": "+1-268",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 24,
        "name": "Benin",
        "iso3": "BEN",
        "phonecode": "229",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 106,
        "name": "Italy",
        "iso3": "ITA",
        "phonecode": "39",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 118,
        "name": "Laos",
        "iso3": "LAO",
        "phonecode": "856",
        "currency": "LAK",
        "currency_symbol": "₭",
        "emoji": "??"
    },
    {
        "id": 121,
        "name": "Lesotho",
        "iso3": "LSO",
        "phonecode": "266",
        "currency": "LSL",
        "currency_symbol": "L",
        "emoji": "??"
    },
    {
        "id": 37,
        "name": "Cambodia",
        "iso3": "KHM",
        "phonecode": "855",
        "currency": "KHR",
        "currency_symbol": "KHR",
        "emoji": "??"
    },
    {
        "id": 45,
        "name": "China",
        "iso3": "CHN",
        "phonecode": "86",
        "currency": "CNY",
        "currency_symbol": "¥",
        "emoji": "??"
    },
    {
        "id": 68,
        "name": "Estonia",
        "iso3": "EST",
        "phonecode": "372",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 15,
        "name": "Austria",
        "iso3": "AUT",
        "phonecode": "43",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 18,
        "name": "Bahrain",
        "iso3": "BHR",
        "phonecode": "973",
        "currency": "BHD",
        "currency_symbol": ".د.ب",
        "emoji": "??"
    },
    {
        "id": 26,
        "name": "Bhutan",
        "iso3": "BTN",
        "phonecode": "975",
        "currency": "BTN",
        "currency_symbol": "Nu.",
        "emoji": "??"
    },
    {
        "id": 47,
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "phonecode": "61",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 56,
        "name": "Cuba",
        "iso3": "CUB",
        "phonecode": "53",
        "currency": "CUP",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 61,
        "name": "Dominica",
        "iso3": "DMA",
        "phonecode": "+1-767",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 111,
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "phonecode": "7",
        "currency": "KZT",
        "currency_symbol": "лв",
        "emoji": "??"
    },
    {
        "id": 203,
        "name": "South Georgia",
        "iso3": "SGS",
        "phonecode": null,
        "currency": "GBP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 13,
        "name": "Aruba",
        "iso3": "ABW",
        "phonecode": "297",
        "currency": "AWG",
        "currency_symbol": "ƒ",
        "emoji": "??"
    },
    {
        "id": 152,
        "name": "Nauru",
        "iso3": "NRU",
        "phonecode": "674",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 183,
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "phonecode": "+1-869",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 189,
        "name": "Samoa",
        "iso3": "WSM",
        "phonecode": "685",
        "currency": "WST",
        "currency_symbol": "SAT",
        "emoji": "??"
    },
    {
        "id": 165,
        "name": "Oman",
        "iso3": "OMN",
        "phonecode": "968",
        "currency": "OMR",
        "currency_symbol": ".ع.ر",
        "emoji": "??"
    },
    {
        "id": 225,
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "phonecode": "+1-649",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 141,
        "name": "Mexico",
        "iso3": "MEX",
        "phonecode": "52",
        "currency": "MXN",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 145,
        "name": "Mongolia",
        "iso3": "MNG",
        "phonecode": "976",
        "currency": "MNT",
        "currency_symbol": "₮",
        "emoji": "??"
    },
    {
        "id": 191,
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "phonecode": "239",
        "currency": "STD",
        "currency_symbol": "Db",
        "emoji": "??"
    },
    {
        "id": 245,
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "phonecode": "263",
        "currency": "ZWL",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 148,
        "name": "Morocco",
        "iso3": "MAR",
        "phonecode": "212",
        "currency": "MAD",
        "currency_symbol": "DH",
        "emoji": "??"
    },
    {
        "id": 168,
        "name": "Palestinian Territory Occupied",
        "iso3": "PSE",
        "phonecode": "970",
        "currency": "ILS",
        "currency_symbol": "₪",
        "emoji": "??"
    },
    {
        "id": 184,
        "name": "Saint Lucia",
        "iso3": "LCA",
        "phonecode": "+1-758",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 196,
        "name": "Sierra Leone",
        "iso3": "SLE",
        "phonecode": "232",
        "currency": "SLL",
        "currency_symbol": "Le",
        "emoji": "??"
    },
    {
        "id": 211,
        "name": "Sweden",
        "iso3": "SWE",
        "phonecode": "46",
        "currency": "SEK",
        "currency_symbol": "kr",
        "emoji": "??"
    },
    {
        "id": 233,
        "name": "Uruguay",
        "iso3": "URY",
        "phonecode": "598",
        "currency": "UYU",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 128,
        "name": "Macedonia",
        "iso3": "MKD",
        "phonecode": "389",
        "currency": "MKD",
        "currency_symbol": "ден",
        "emoji": "??"
    },
    {
        "id": 172,
        "name": "Peru",
        "iso3": "PER",
        "phonecode": "51",
        "currency": "PEN",
        "currency_symbol": "S/.",
        "emoji": "??"
    },
    {
        "id": 192,
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "phonecode": "966",
        "currency": "SAR",
        "currency_symbol": "﷼",
        "emoji": "??"
    },
    {
        "id": 231,
        "name": "United States",
        "iso3": "USA",
        "phonecode": "1",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 159,
        "name": "Niger",
        "iso3": "NER",
        "phonecode": "227",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 161,
        "name": "Niue",
        "iso3": "NIU",
        "phonecode": "683",
        "currency": "NZD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 185,
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "phonecode": "508",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 193,
        "name": "Senegal",
        "iso3": "SEN",
        "phonecode": "221",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 213,
        "name": "Syria",
        "iso3": "SYR",
        "phonecode": "963",
        "currency": "SYP",
        "currency_symbol": "LS",
        "emoji": "??"
    },
    {
        "id": 237,
        "name": "Venezuela",
        "iso3": "VEN",
        "phonecode": "58",
        "currency": "VEF",
        "currency_symbol": "Bs",
        "emoji": "??"
    },
    {
        "id": 240,
        "name": "Virgin Islands (US)",
        "iso3": "VIR",
        "phonecode": "+1-340",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 143,
        "name": "Moldova",
        "iso3": "MDA",
        "phonecode": "373",
        "currency": "MDL",
        "currency_symbol": "L",
        "emoji": "??"
    },
    {
        "id": 147,
        "name": "Montserrat",
        "iso3": "MSR",
        "phonecode": "+1-664",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 200,
        "name": "Solomon Islands",
        "iso3": "SLB",
        "phonecode": "677",
        "currency": "SBD",
        "currency_symbol": "Si$",
        "emoji": "??"
    },
    {
        "id": 207,
        "name": "Sudan",
        "iso3": "SDN",
        "phonecode": "249",
        "currency": "SDG",
        "currency_symbol": ".س.ج",
        "emoji": "??"
    },
    {
        "id": 235,
        "name": "Vanuatu",
        "iso3": "VUT",
        "phonecode": "678",
        "currency": "VUV",
        "currency_symbol": "VT",
        "emoji": "??"
    },
    {
        "id": 130,
        "name": "Malawi",
        "iso3": "MWI",
        "phonecode": "265",
        "currency": "MWK",
        "currency_symbol": "MK",
        "emoji": "??"
    },
    {
        "id": 157,
        "name": "New Zealand",
        "iso3": "NZL",
        "phonecode": "64",
        "currency": "NZD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 170,
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "phonecode": "675",
        "currency": "PGK",
        "currency_symbol": "K",
        "emoji": "??"
    },
    {
        "id": 174,
        "name": "Pitcairn Island",
        "iso3": "PCN",
        "phonecode": "870",
        "currency": "NZD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 214,
        "name": "Taiwan",
        "iso3": "TWN",
        "phonecode": "886",
        "currency": "TWD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 127,
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "phonecode": "853",
        "currency": "MOP",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 190,
        "name": "San Marino",
        "iso3": "SMR",
        "phonecode": "378",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 208,
        "name": "Suriname",
        "iso3": "SUR",
        "phonecode": "597",
        "currency": "SRD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 218,
        "name": "Togo",
        "iso3": "TGO",
        "phonecode": "228",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 247,
        "name": "Curaçao",
        "iso3": "CUW",
        "phonecode": "599",
        "currency": "ANG",
        "currency_symbol": "ƒ",
        "emoji": "??"
    },
    {
        "id": 164,
        "name": "Norway",
        "iso3": "NOR",
        "phonecode": "47",
        "currency": "NOK",
        "currency_symbol": "kr",
        "emoji": "??"
    },
    {
        "id": 166,
        "name": "Pakistan",
        "iso3": "PAK",
        "phonecode": "92",
        "currency": "PKR",
        "currency_symbol": "₨",
        "emoji": "??"
    },
    {
        "id": 226,
        "name": "Tuvalu",
        "iso3": "TUV",
        "phonecode": "688",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 243,
        "name": "Yemen",
        "iso3": "YEM",
        "phonecode": "967",
        "currency": "YER",
        "currency_symbol": "﷼",
        "emoji": "??"
    },
    {
        "id": 133,
        "name": "Mali",
        "iso3": "MLI",
        "phonecode": "223",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 198,
        "name": "Slovakia",
        "iso3": "SVK",
        "phonecode": "421",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 238,
        "name": "Vietnam",
        "iso3": "VNM",
        "phonecode": "84",
        "currency": "VND",
        "currency_symbol": "₫",
        "emoji": "??"
    },
    {
        "id": 139,
        "name": "Mauritius",
        "iso3": "MUS",
        "phonecode": "230",
        "currency": "MUR",
        "currency_symbol": "₨",
        "emoji": "??"
    },
    {
        "id": 151,
        "name": "Namibia",
        "iso3": "NAM",
        "phonecode": "264",
        "currency": "NAD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 160,
        "name": "Nigeria",
        "iso3": "NGA",
        "phonecode": "234",
        "currency": "NGN",
        "currency_symbol": "₦",
        "emoji": "??"
    },
    {
        "id": 177,
        "name": "Qatar",
        "iso3": "QAT",
        "phonecode": "974",
        "currency": "QAR",
        "currency_symbol": "ق.ر",
        "emoji": "??"
    },
    {
        "id": 206,
        "name": "Sri Lanka",
        "iso3": "LKA",
        "phonecode": "94",
        "currency": "LKR",
        "currency_symbol": "Rs",
        "emoji": "??"
    },
    {
        "id": 215,
        "name": "Tajikistan",
        "iso3": "TJK",
        "phonecode": "992",
        "currency": "TJS",
        "currency_symbol": "SM",
        "emoji": "??"
    },
    {
        "id": 156,
        "name": "New Caledonia",
        "iso3": "NCL",
        "phonecode": "687",
        "currency": "XPF",
        "currency_symbol": "₣",
        "emoji": "??"
    },
    {
        "id": 176,
        "name": "Portugal",
        "iso3": "PRT",
        "phonecode": "351",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 181,
        "name": "Rwanda",
        "iso3": "RWA",
        "phonecode": "250",
        "currency": "RWF",
        "currency_symbol": "FRw",
        "emoji": "??"
    },
    {
        "id": 144,
        "name": "Monaco",
        "iso3": "MCO",
        "phonecode": "377",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 150,
        "name": "Myanmar",
        "iso3": "MMR",
        "phonecode": "95",
        "currency": "MMK",
        "currency_symbol": "K",
        "emoji": "??"
    },
    {
        "id": 179,
        "name": "Romania",
        "iso3": "ROU",
        "phonecode": "40",
        "currency": "RON",
        "currency_symbol": "lei",
        "emoji": "??"
    },
    {
        "id": 220,
        "name": "Tonga",
        "iso3": "TON",
        "phonecode": "676",
        "currency": "TOP",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 137,
        "name": "Martinique",
        "iso3": "MTQ",
        "phonecode": "596",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 167,
        "name": "Palau",
        "iso3": "PLW",
        "phonecode": "680",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 205,
        "name": "Spain",
        "iso3": "ESP",
        "phonecode": "34",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 210,
        "name": "Swaziland",
        "iso3": "SWZ",
        "phonecode": "268",
        "currency": "SZL",
        "currency_symbol": "E",
        "emoji": "??"
    },
    {
        "id": 223,
        "name": "Turkey",
        "iso3": "TUR",
        "phonecode": "90",
        "currency": "TRY",
        "currency_symbol": "₺",
        "emoji": "??"
    },
    {
        "id": 239,
        "name": "Virgin Islands (British)",
        "iso3": "VGB",
        "phonecode": "+1-284",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 241,
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "phonecode": "681",
        "currency": "XPF",
        "currency_symbol": "₣",
        "emoji": "??"
    },
    {
        "id": 132,
        "name": "Maldives",
        "iso3": "MDV",
        "phonecode": "960",
        "currency": "MVR",
        "currency_symbol": "Rf",
        "emoji": "??"
    },
    {
        "id": 146,
        "name": "Montenegro",
        "iso3": "MNE",
        "phonecode": "382",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 175,
        "name": "Poland",
        "iso3": "POL",
        "phonecode": "48",
        "currency": "PLN",
        "currency_symbol": "zł",
        "emoji": "??"
    },
    {
        "id": 195,
        "name": "Seychelles",
        "iso3": "SYC",
        "phonecode": "248",
        "currency": "SCR",
        "currency_symbol": "SRe",
        "emoji": "??"
    },
    {
        "id": 212,
        "name": "Switzerland",
        "iso3": "CHE",
        "phonecode": "41",
        "currency": "CHF",
        "currency_symbol": "CHf",
        "emoji": "??"
    },
    {
        "id": 219,
        "name": "Tokelau",
        "iso3": "TKL",
        "phonecode": "690",
        "currency": "NZD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 209,
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "phonecode": "47",
        "currency": "NOK",
        "currency_symbol": "kr",
        "emoji": "??"
    },
    {
        "id": 217,
        "name": "Thailand",
        "iso3": "THA",
        "phonecode": "66",
        "currency": "THB",
        "currency_symbol": "฿",
        "emoji": "??"
    },
    {
        "id": 229,
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "phonecode": "971",
        "currency": "AED",
        "currency_symbol": "إ.د",
        "emoji": "??"
    },
    {
        "id": 11,
        "name": "Argentina",
        "iso3": "ARG",
        "phonecode": "54",
        "currency": "ARS",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 83,
        "name": "Gibraltar",
        "iso3": "GIB",
        "phonecode": "350",
        "currency": "GIP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 94,
        "name": "Haiti",
        "iso3": "HTI",
        "phonecode": "509",
        "currency": "HTG",
        "currency_symbol": "G",
        "emoji": "??"
    },
    {
        "id": 131,
        "name": "Malaysia",
        "iso3": "MYS",
        "phonecode": "60",
        "currency": "MYR",
        "currency_symbol": "RM",
        "emoji": "??"
    },
    {
        "id": 158,
        "name": "Nicaragua",
        "iso3": "NIC",
        "phonecode": "505",
        "currency": "NIO",
        "currency_symbol": "C$",
        "emoji": "??"
    },
    {
        "id": 194,
        "name": "Serbia",
        "iso3": "SRB",
        "phonecode": "381",
        "currency": "RSD",
        "currency_symbol": "din",
        "emoji": "??"
    },
    {
        "id": 5,
        "name": "American Samoa",
        "iso3": "ASM",
        "phonecode": "+1-684",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 40,
        "name": "Cape Verde",
        "iso3": "CPV",
        "phonecode": "238",
        "currency": "CVE",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 54,
        "name": "Cote D'Ivoire (Ivory Coast)",
        "iso3": "CIV",
        "phonecode": "225",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 104,
        "name": "Ireland",
        "iso3": "IRL",
        "phonecode": "353",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 140,
        "name": "Mayotte",
        "iso3": "MYT",
        "phonecode": "262",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 228,
        "name": "Ukraine",
        "iso3": "UKR",
        "phonecode": "380",
        "currency": "UAH",
        "currency_symbol": "₴",
        "emoji": "??"
    },
    {
        "id": 30,
        "name": "Bouvet Island",
        "iso3": "BVT",
        "phonecode": "0055",
        "currency": "NOK",
        "currency_symbol": "kr",
        "emoji": "??"
    },
    {
        "id": 135,
        "name": "Man (Isle of)",
        "iso3": "IMN",
        "phonecode": "+44-1624",
        "currency": "GBP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 188,
        "name": "Saint-Martin (French part)",
        "iso3": "MAF",
        "phonecode": "590",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 221,
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "phonecode": "+1-868",
        "currency": "TTD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 31,
        "name": "Brazil",
        "iso3": "BRA",
        "phonecode": "55",
        "currency": "BRL",
        "currency_symbol": "R$",
        "emoji": "??"
    },
    {
        "id": 71,
        "name": "Faroe Islands",
        "iso3": "FRO",
        "phonecode": "298",
        "currency": "DKK",
        "currency_symbol": "Kr.",
        "emoji": "??"
    },
    {
        "id": 138,
        "name": "Mauritania",
        "iso3": "MRT",
        "phonecode": "222",
        "currency": "MRO",
        "currency_symbol": "MRU",
        "emoji": "??"
    },
    {
        "id": 199,
        "name": "Slovenia",
        "iso3": "SVN",
        "phonecode": "386",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 34,
        "name": "Bulgaria",
        "iso3": "BGR",
        "phonecode": "359",
        "currency": "BGN",
        "currency_symbol": "Лв.",
        "emoji": "??"
    },
    {
        "id": 66,
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "phonecode": "240",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "emoji": "??"
    },
    {
        "id": 105,
        "name": "Israel",
        "iso3": "ISR",
        "phonecode": "972",
        "currency": "ILS",
        "currency_symbol": "₪",
        "emoji": "??"
    },
    {
        "id": 149,
        "name": "Mozambique",
        "iso3": "MOZ",
        "phonecode": "258",
        "currency": "MZN",
        "currency_symbol": "MT",
        "emoji": "??"
    },
    {
        "id": 173,
        "name": "Philippines",
        "iso3": "PHL",
        "phonecode": "63",
        "currency": "PHP",
        "currency_symbol": "₱",
        "emoji": "??"
    },
    {
        "id": 197,
        "name": "Singapore",
        "iso3": "SGP",
        "phonecode": "65",
        "currency": "SGD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 230,
        "name": "United Kingdom",
        "iso3": "GBR",
        "phonecode": "44",
        "currency": "GBP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 32,
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "phonecode": "246",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 74,
        "name": "France",
        "iso3": "FRA",
        "phonecode": "33",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 116,
        "name": "Kuwait",
        "iso3": "KWT",
        "phonecode": "965",
        "currency": "KWD",
        "currency_symbol": "ك.د",
        "emoji": "??"
    },
    {
        "id": 142,
        "name": "Micronesia",
        "iso3": "FSM",
        "phonecode": "691",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 186,
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "phonecode": "+1-784",
        "currency": "XCD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 216,
        "name": "Tanzania",
        "iso3": "TZA",
        "phonecode": "255",
        "currency": "TZS",
        "currency_symbol": "TSh",
        "emoji": "??"
    },
    {
        "id": 38,
        "name": "Cameroon",
        "iso3": "CMR",
        "phonecode": "237",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "emoji": "??"
    },
    {
        "id": 80,
        "name": "Georgia",
        "iso3": "GEO",
        "phonecode": "995",
        "currency": "GEL",
        "currency_symbol": "ლ",
        "emoji": "??"
    },
    {
        "id": 109,
        "name": "Jersey",
        "iso3": "JEY",
        "phonecode": "+44-1534",
        "currency": "GBP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 136,
        "name": "Marshall Islands",
        "iso3": "MHL",
        "phonecode": "692",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 169,
        "name": "Panama",
        "iso3": "PAN",
        "phonecode": "507",
        "currency": "PAB",
        "currency_symbol": "B/.",
        "emoji": "??"
    },
    {
        "id": 171,
        "name": "Paraguay",
        "iso3": "PRY",
        "phonecode": "595",
        "currency": "PYG",
        "currency_symbol": "₲",
        "emoji": "??"
    },
    {
        "id": 234,
        "name": "Uzbekistan",
        "iso3": "UZB",
        "phonecode": "998",
        "currency": "UZS",
        "currency_symbol": "лв",
        "emoji": "??"
    },
    {
        "id": 14,
        "name": "Australia",
        "iso3": "AUS",
        "phonecode": "61",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 81,
        "name": "Germany",
        "iso3": "DEU",
        "phonecode": "49",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 108,
        "name": "Japan",
        "iso3": "JPN",
        "phonecode": "81",
        "currency": "JPY",
        "currency_symbol": "¥",
        "emoji": "??"
    },
    {
        "id": 202,
        "name": "South Africa",
        "iso3": "ZAF",
        "phonecode": "27",
        "currency": "ZAR",
        "currency_symbol": "R",
        "emoji": "??"
    },
    {
        "id": 224,
        "name": "Turkmenistan",
        "iso3": "TKM",
        "phonecode": "993",
        "currency": "TMT",
        "currency_symbol": "T",
        "emoji": "??"
    },
    {
        "id": 89,
        "name": "Guatemala",
        "iso3": "GTM",
        "phonecode": "502",
        "currency": "GTQ",
        "currency_symbol": "Q",
        "emoji": "??"
    },
    {
        "id": 129,
        "name": "Madagascar",
        "iso3": "MDG",
        "phonecode": "261",
        "currency": "MGA",
        "currency_symbol": "Ar",
        "emoji": "??"
    },
    {
        "id": 204,
        "name": "South Sudan",
        "iso3": "SSD",
        "phonecode": "211",
        "currency": "SSP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 244,
        "name": "Zambia",
        "iso3": "ZMB",
        "phonecode": "260",
        "currency": "ZMW",
        "currency_symbol": "ZK",
        "emoji": "??"
    },
    {
        "id": 50,
        "name": "Congo",
        "iso3": "COG",
        "phonecode": "242",
        "currency": "XAF",
        "currency_symbol": "FC",
        "emoji": "??"
    },
    {
        "id": 79,
        "name": "Gambia The",
        "iso3": "GMB",
        "phonecode": "220",
        "currency": "GMD",
        "currency_symbol": "D",
        "emoji": "??"
    },
    {
        "id": 92,
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "phonecode": "245",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "emoji": "??"
    },
    {
        "id": 155,
        "name": "Netherlands The",
        "iso3": "NLD",
        "phonecode": "31",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 248,
        "name": "Sint Maarten (Dutch part)",
        "iso3": "SXM",
        "phonecode": "1721",
        "currency": "ANG",
        "currency_symbol": "ƒ",
        "emoji": "??"
    },
    {
        "id": 51,
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "phonecode": "243",
        "currency": "CDF",
        "currency_symbol": "FC",
        "emoji": "??"
    },
    {
        "id": 63,
        "name": "Ecuador",
        "iso3": "ECU",
        "phonecode": "593",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 107,
        "name": "Jamaica",
        "iso3": "JAM",
        "phonecode": "+1-876",
        "currency": "JMD",
        "currency_symbol": "J$",
        "emoji": "??"
    },
    {
        "id": 153,
        "name": "Nepal",
        "iso3": "NPL",
        "phonecode": "977",
        "currency": "NPR",
        "currency_symbol": "₨",
        "emoji": "??"
    },
    {
        "id": 187,
        "name": "Saint-Barthelemy",
        "iso3": "BLM",
        "phonecode": "590",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 236,
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "phonecode": "379",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 16,
        "name": "Azerbaijan",
        "iso3": "AZE",
        "phonecode": "994",
        "currency": "AZN",
        "currency_symbol": "m",
        "emoji": "??"
    },
    {
        "id": 42,
        "name": "Central African Republic",
        "iso3": "CAF",
        "phonecode": "236",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "emoji": "??"
    },
    {
        "id": 75,
        "name": "French Guiana",
        "iso3": "GUF",
        "phonecode": "594",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 85,
        "name": "Greenland",
        "iso3": "GRL",
        "phonecode": "299",
        "currency": "DKK",
        "currency_symbol": "Kr.",
        "emoji": "??"
    },
    {
        "id": 115,
        "name": "Korea South",
        "iso3": "KOR",
        "phonecode": "82",
        "currency": "KRW",
        "currency_symbol": "₩",
        "emoji": "??"
    },
    {
        "id": 180,
        "name": "Russia",
        "iso3": "RUS",
        "phonecode": "7",
        "currency": "RUB",
        "currency_symbol": "₽",
        "emoji": "??"
    },
    {
        "id": 182,
        "name": "Saint Helena",
        "iso3": "SHN",
        "phonecode": "290",
        "currency": "SHP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 33,
        "name": "Brunei",
        "iso3": "BRN",
        "phonecode": "673",
        "currency": "BND",
        "currency_symbol": "B$",
        "emoji": "??"
    },
    {
        "id": 82,
        "name": "Ghana",
        "iso3": "GHA",
        "phonecode": "233",
        "currency": "GHS",
        "currency_symbol": "GH₵",
        "emoji": "??"
    },
    {
        "id": 163,
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "phonecode": "+1-670",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 246,
        "name": "Kosovo",
        "iso3": "XKX",
        "phonecode": "383",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 2,
        "name": "Aland Islands",
        "iso3": "ALA",
        "phonecode": "+358-18",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 67,
        "name": "Eritrea",
        "iso3": "ERI",
        "phonecode": "291",
        "currency": "ERN",
        "currency_symbol": "Nfk",
        "emoji": "??"
    },
    {
        "id": 90,
        "name": "Guernsey and Alderney",
        "iso3": "GGY",
        "phonecode": "+44-1481",
        "currency": "GBP",
        "currency_symbol": "£",
        "emoji": "??"
    },
    {
        "id": 154,
        "name": "Bonaire, Sint Eustatius and Saba",
        "iso3": "BES",
        "phonecode": "599",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 222,
        "name": "Tunisia",
        "iso3": "TUN",
        "phonecode": "216",
        "currency": "TND",
        "currency_symbol": "ت.د",
        "emoji": "??"
    },
    {
        "id": 232,
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "phonecode": "1",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 20,
        "name": "Barbados",
        "iso3": "BRB",
        "phonecode": "+1-246",
        "currency": "BBD",
        "currency_symbol": "Bds$",
        "emoji": "??"
    },
    {
        "id": 59,
        "name": "Denmark",
        "iso3": "DNK",
        "phonecode": "45",
        "currency": "DKK",
        "currency_symbol": "Kr.",
        "emoji": "??"
    },
    {
        "id": 97,
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "phonecode": "852",
        "currency": "HKD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 134,
        "name": "Malta",
        "iso3": "MLT",
        "phonecode": "356",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 178,
        "name": "Reunion",
        "iso3": "REU",
        "phonecode": "262",
        "currency": "EUR",
        "currency_symbol": "€",
        "emoji": "??"
    },
    {
        "id": 201,
        "name": "Somalia",
        "iso3": "SOM",
        "phonecode": "252",
        "currency": "SOS",
        "currency_symbol": "Sh.so.",
        "emoji": "??"
    },
    {
        "id": 25,
        "name": "Bermuda",
        "iso3": "BMU",
        "phonecode": "+1-441",
        "currency": "BMD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 28,
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "phonecode": "387",
        "currency": "BAM",
        "currency_symbol": "KM",
        "emoji": "??"
    },
    {
        "id": 65,
        "name": "El Salvador",
        "iso3": "SLV",
        "phonecode": "503",
        "currency": "USD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 162,
        "name": "Norfolk Island",
        "iso3": "NFK",
        "phonecode": "672",
        "currency": "AUD",
        "currency_symbol": "$",
        "emoji": "??"
    },
    {
        "id": 227,
        "name": "Uganda",
        "iso3": "UGA",
        "phonecode": "256",
        "currency": "UGX",
        "currency_symbol": "USh",
        "emoji": "??"
    },
    {
        "id": 242,
        "name": "Western Sahara",
        "iso3": "ESH",
        "phonecode": "212",
        "currency": "MAD",
        "currency_symbol": "MAD",
        "emoji": "??"
    }
]