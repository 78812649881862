import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Injector,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    project_field_type,
    task_field_type,
} from 'src/app/shared/global/constants';
import { RegexEnum } from 'src/app/shared/global/regex-enum';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { ProjectManagementService } from 'src/app/shared/services/project-service/project-management.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { OrganizationSettingsService } from 'src/app/shared/services/organization-settings/organization-settings.service';
import { Store } from '@ngrx/store';
import { CompanyDetailsService } from 'src/app/shared/services/organization/company-details.service';
import { ApiService } from 'src/app/shared/services/api-service/api.service';

@Component({
    selector: 'app-add-edit-project',
    templateUrl: './add-edit-project.component.html',
    styleUrls: ['./add-edit-project.component.scss'],
})
export class AddEditProjectComponent implements OnInit {

    assigneeChange = 1;
    managerChange = 1;
    projectDetailForm: FormGroup;
    todayDate: any = new Date();
    selectedDueDate: Date | null;
    selectedCompanyId: any;
    selectedMember: any;
    public projectManager: any[] = [];
    allCheckManager = false;
    allCheckMember = false;
    projectManagerListData: any = [];
    originalprojectManagerList: any = [];
    universalprojectManagerList: any = [];
    projectMembers: any = [];
    projectMembersListData: any = [];
    originalprojectMembersList: any = [];
    userRole: any;
    universalManagerList: any = [];
    universalMemberList: any = [];
    outsiderEmailArr: any = [];
    clientMatPreview: any = [];
    originalClientListData: any = [];
    clientListData: any = [];
    selectedClient: any = [];
    isSetNoDueDate = false;
    loginUserData: any;
    companyData: any;
    projectCreator: any;
    projectDetails: any;
    public notificationService: NotificationService;
    subscription: Subscription;
    memberRes: any;
    userId: any;
    isShowClientList: boolean;
    taskState: any;
    disableAllFunctionality = true;
    selectedAssignees: any[] = [];
    selectedGroup: any[] = [];
    listArrName = '';
    selectedMatPreview: any[] = [];
    originalFollowerListData: any[] = [];
    originalGroupListData: any[] = [];
    selectedAssigneesFromBE: any;
    coMembersList: any;
    incValue = 0;
    assigneeIncValue = 0;
    @Output('hide_userdata') hide_userdata = new EventEmitter();
    logincreator: any = 0;
    ownerId: any;
    groupMembers: any[] = [];
    empty = false;
    searchValue: any = '';
    @ViewChild('inputField') inputField: ElementRef;
    deletedGroup: any[] = [];
    constructor(
        public injector: Injector,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<AddEditProjectComponent>,
        private formBuilder: FormBuilder,
        private taskService: TasksService,
        public userDetailService: UserDetailsService,
        public utils: UtilsService,
        public toastrService: ToastrService,
        public projectManagementService: ProjectManagementService,
        public organizationSettings: OrganizationSettingsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<{ taskState: TaskState }>,
        public companyDetailService: CompanyDetailsService,
        private cdr: ChangeDetectorRef,
        private apiService: ApiService,
    ) {
        this.dialogRef.disableClose = true;
        this.translate.setDefaultLang('en');
        this.notificationService =
            injector.get<NotificationService>(NotificationService);
    }

    async ngOnInit() {
        this.formInit();

        this.userId = await this.userDetailService.encryptUserId;
        this.store.select('taskState').subscribe((ele: any) => {
            if (!ele.taskState) return;
            this.taskState = ele?.taskState;
        });
        this.selectedCompanyId = await this.userDetailService.getSelectedCompanyId();
        this.userRole = await this.userDetailService.getUserRolePermissions();
        const loginUserData: any = await this.userDetailService.getLoginUserData();
        const trimmedString = loginUserData?.user_name.replace(/\s+/g, ' ');
        const name = trimmedString?.split(' ');
        this.loginUserData = {
            user_id: loginUserData?.id,
            first_name: name[0].trim(),
            last_name: name[1].trim(),
            profile_image:
                loginUserData?.profile_image?.charAt(0) == 'h'
                    ? loginUserData?.profile_image
                    : '#FAB222',
        };

        this.getCompanyDetails(this.selectedCompanyId);
        await this.getMemberListData();
        await this.getManagerListData();
        await this.getclientList();
        this.removedIdFromMat();
        this.companyData = await this.userDetailService.getSelectedCompanyData();
        this.companyData['name'] = this.companyData?.company_name;
        if (this.companyData && 'company_name' in this.companyData) {
            delete this.companyData.company_name;
        }
        if (this.companyData && this.companyData.hasOwnProperty('user_company_relations')) {
            delete this.companyData.user_company_relations;
        }
        if (this.companyData && 'member_count' in this.companyData) {
            delete this.companyData.member_count;
        }
        if (this.companyData && this.companyData.hasOwnProperty('file_size') ) {
            delete this.companyData?.file_size;
        }

        this.taskState?.reportConfig?.company_all_members.forEach(
            (element: any) => {
                if (!element.is_deleted) {
                    this.originalFollowerListData.push(element);
                }
            }
        );
        this.originalFollowerListData = this.originalFollowerListData.map(
            (item: any) => {
                return {
                    ...item,
                    name: `${item.first_name} ${item.last_name}`,
                };
            }
        );
        this.originalGroupListData = this.taskState.reportConfig.group_details.map(
            (item: any) => {
                return {
                    id: item.id,
                    name: item.group_name,
                    color_id: item.color_id,
                    company_id: item.company_id,
                    created_by: item.created_by,
                    members: item.members_details,
                    is_deleted: item.is_deleted,
                };
            }
        );

        if (this.data.projectId) {
            await this.getProjectDetailsById(this.data.projectId);

        }
    }

    async getclientList() {
        const client = this.taskState?.reportConfig?.client_details;
        if (client) {
            client?.forEach((ele: any) => {
                const dataObj = {
                    id: ele.id,
                    name: ele.client_name + ' - ' + ele.company_name,
                    isCheck: false,
                    profile_image: ele.profile_image,
                    client_name: ele.client_name,
                };
                this.clientListData.push(dataObj);
            });
            this.originalClientListData = this.clientListData;
        }
    }

    async getMemberListData() {
        let memberArr: any[] = [];
        const client = this.taskState?.reportConfig?.company_members;
        if (client) {
            await client.forEach((ele: any) => {
                if (ele.id != this.userRole.memberId) {
                    const data = {
                        name: ele.first_name + ' ' + ele.last_name,
                        first_name: ele.first_name,
                        last_name: ele.last_name,
                        id: ele.id,
                        email: ele.email,
                        profile_image: ele.profile_image,
                        isCheck: false,
                    };
                    memberArr.push(data);
                }

            });
            this.projectCreator = await memberArr.find(
                (ele: any) => ele.id === this.userRole.memberId
            );
            this.loginUserData['member_id'] = this.userRole.memberId;
            memberArr = memberArr.filter(
                (data: any) => data.id !== this.userRole.memberId
            );
            this.memberRes = memberArr;
            this.projectMembersListData = memberArr;
            this.originalprojectMembersList = memberArr;
            this.universalMemberList = memberArr;
        }
    }

    async getManagerListData() {
        const managerArr: any = [];
        if (this.memberRes) {
            await this.memberRes.forEach((ele: any) => {
                const data = {
                    name: ele.first_name + ' ' + ele.last_name,
                    first_name: ele.first_name,
                    last_name: ele.last_name,
                    id: ele.id,
                    email: ele.email,
                    profile_image: ele.profile_image,
                };
                managerArr.push(data);
            });
            this.projectManagerListData = managerArr;
            this.originalprojectManagerList = managerArr;
            this.universalprojectManagerList = Object.assign([], managerArr);
            this.universalManagerList = managerArr;
        }
    }

    formInit() {
        this.projectDetailForm = this.formBuilder.group({
            projectName: ["", [Validators.required]],
            projectDesc: ["", [Validators.required]],
            outsiderEmail: ["", [Validators.pattern(RegexEnum.email)]],
            projectStartDate: ["", [Validators.required]],
            projectEndDate: ["", [Validators.required]],
            projectAssignee: [""],
        });
    }

    setOutsiderEmails() {
        this.outsiderEmailArr.push({
            email: this.projectDetailForm.controls['outsiderEmail'].value,
        });
        this.projectDetailForm.controls['outsiderEmail'].reset();
    }

    onToppingEmailRemoved(index: number) {
        this.outsiderEmailArr.splice(index, 1);
    }

    onToppingRemoved(type: any, data: any) {
        type === 'manager'
            ? this.selectedProjectManager({ checked: false }, data)
            : this.selectedProjectMembers({ checked: false }, data);
    }

    projectStartDate(date?: any) {
        this.projectDetailForm.controls['projectStartDate'].setValue(date);
    }

    projectEndDate(date?: any) {
        this.projectDetailForm.controls['projectEndDate'].setValue(date);
    }

    setNoDueDate(event: any) {
        this.isSetNoDueDate = event.checked;
        if (this.isSetNoDueDate) {
            this.projectDetailForm.controls['projectEndDate'].setValue(null);
            this.projectDetailForm.controls['projectEndDate'].clearValidators();
            this.projectDetailForm.controls['projectEndDate'].setErrors(null);
            this.projectDetailForm.updateValueAndValidity();
            this.projectDetailForm.markAsDirty();
        } else {
            this.projectDetailForm.controls['projectEndDate'].setValue('');
            this.projectDetailForm.controls['projectEndDate'].setValidators(
                Validators.required
            );
            this.projectDetailForm.controls['projectEndDate'].setErrors({
                required: true,
            });
            this.projectDetailForm.updateValueAndValidity();
            this.projectDetailForm.markAsDirty();
        }
    }

    removedIdFromMat() {
        this.subscription = this.taskService.removeIdFromPreview.subscribe(
            (data: any) => {
                if (data.listArrName === task_field_type.client && data) {
                    this.originalClientListData = this.originalClientListData.map((ele: any) => {
                        if (data.removeId === ele.id) {
                            ele.isCheck = false;
                        }
                        return ele;
                    });

                    this.selectedClient = this.clientListData
                        .filter((clientData: any) => clientData.isCheck)
                        .map((ele: any) => ele.id);

                    this.onSelectClientCheckBox(this.selectedClient);
                }
            }
        );
    }


    async onSelectClientCheckBox(selectedId: any) {
        if (selectedId.length) {
            await selectedId.forEach((ele: any) => {
                this.clientListData.forEach((data: any) => {
                    if (data.id === ele) {
                        data.isCheck = true;
                    } else {
                        data.isCheck = false;
                    }
                });
            });
            this.clientListData = [...this.clientListData];
        }
        this.selectedClient = selectedId;
        this.setMatPreview('client');
    }

    setMatPreview(listArrName: any) {
        if (listArrName === task_field_type.client) {
            this.clientMatPreview = this.originalClientListData.filter((ele: any) => {
                if (this.selectedClient.includes(ele.id)) {
                    return ele;
                }
            });
        }
    }

    async selectAllManager(event: any) {
        if (event.checked) {
            this.projectManagerListData = this.projectManagerListData.filter(
                (data: any) => {
                    if (this.data.owner) {
                        return data.id !== this.data.owner;
                    }
                    else {

                        return data.id !== this.userRole.memberId;
                    }
                }
            );
        }
        await this.projectManagerListData.forEach((ele: any) => {
            if (event.checked) {
                ele.ischeck = event.checked;
                if (!this.projectManager.map((ele: any) => ele.id).includes(ele.id)) {
                    this.projectManager.push(ele);
                }
            } else {
                ele.ischeck = false;
                const index = this.projectManager.findIndex(
                    (id_index: any) => id_index.id === ele.id
                );
                this.projectManager.splice(index, 1);
            }
        });
        this.removeFromMemberList();
        this.allCheckManager = this.updateAllCheckManager(
            this.projectManagerListData
        );
    }

    async selectAllMembers(event: any) {
        await this.projectMembersListData.forEach((ele: any) => {
            if (event.checked) {
                ele.isCheck = event.checked;
                if (!this.projectMembers.map((ele_id: any) => ele_id.id).includes(ele.id)) {
                    this.projectMembers.push(ele);
                }
            } else {
                ele.isCheck = false;
                const index = this.projectMembers.findIndex(
                    (memberIndex: any) => memberIndex.id === ele.id
                );
                this.projectMembers.splice(index, 1);
            }
        });
        this.removeFromManagerList();
        this.allCheckMember = this.updateAllCheck(this.projectMembersListData);
    }

    async selectedProjectMembers(event: any, data: any) {
        if (event.checked) {
            this.projectMembers.push(data);
        } else {
            const index = this.projectMembers.findIndex(
                (ele: any) => data.id === ele.id
            );
            this.projectMembers.splice(index, 1);
        }
        await this.projectMembersListData.forEach((ele: any) => {
            if (ele.id === data.id) {
                ele.isCheck = event.checked;
            }
        });
        this.removeFromManagerList();
        this.allCheckMember = this.updateAllCheck(this.projectMembersListData);
        setTimeout(() => {
            this.projectMembersListData = _.orderBy(
                this.projectMembersListData,
                'isCheck',
                'desc'
            );
        }, 100);
    }

    async selectedProjectManager(event: any, data: any) {
        this.managerChange = 0;
        if (event.checked) {
            this.projectManager.push(data);
        } else {
            const index = this.projectManager.findIndex(
                (ele: any) => data.id === ele.id
            );
            this.projectManager.splice(index, 1);
        }
        await this.projectManagerListData.forEach((ele: any) => {
            if (ele.id === data.id) {
                ele.ischeck = event.checked;
            }
        });
        this.removeFromMemberList();
        this.allCheckManager = this.updateAllCheckManager(
            this.projectManagerListData
        );
        setTimeout(() => {
            this.projectManagerListData = _.orderBy(
                this.projectManagerListData,
                'ischeck',
                'desc'
            );
        }, 100);
    }

    removeFromMemberList() {
        const projectManagerIds: any = this.projectManager.map(
            (ele: any) => ele.id
        );
        const newArr: any = this.universalMemberList.filter(
            (ele: any) => !projectManagerIds.includes(ele.id)
        );
        this.projectMembersListData = [...newArr];
        this.originalprojectMembersList = [...newArr];
        this.allCheckMember = this.updateAllCheck(this.projectMembersListData);
    }

    removeFromManagerList() {
        this.allCheckManager = this.updateAllCheckManager(
            this.projectManagerListData
        );
    }

    updateAllCheck(arr: any) {
        return arr?.every((t: any) => t.isCheck) ?? false;
    }

    updateAllCheckManager(arr: any) {
        return arr?.every((t: any) => t.isCheck) ?? false;
    }

    search(event: any, orignalArr: any, _listArr: any, listArrName: any) {
        this.searchValue = event.target.value.toLowerCase();
        const listArrs = orignalArr.filter((ele: any) => {
            const name = ele.name.toLowerCase();
            return name.includes(this.searchValue);
        });
        switch (listArrName) {
        case project_field_type.manager:
            this.projectManagerListData = this.projectManagerListData.filter(
                (data: any) => {
                    if (this.data.owner) {
                        return data.id !== this.data.owner;
                    }
                    else {

                        return data.id !== this.userRole.memberId;
                    }
                }
            );
            this.projectManagerListData = [...listArrs];
            this.allCheckManager = false;
            this.allCheckManager = this.updateAllCheckManager(
                this.projectManagerListData
            );
            break;
        case project_field_type.member:
            this.projectMembersListData = [...listArrs];
            this.allCheckMember = false;
            this.allCheckMember = this.updateAllCheck(this.projectMembersListData);
            break;
        case project_field_type.client:
            this.clientListData = [...listArrs];
            for (const element of this.clientListData) {
                element.isCheck = this.selectedClient && this.selectedClient[0] === element.id;
            }
            return this.clientListData;
        default:
            break;
        }
        this.cdr.detectChanges();
    }

    closeDialog(val: boolean) {
        this.dialogRef.close(val);
    }

    commonTypeArr(arr: any) {
        arr = arr.map((ele: any) => {
            return {
                id: ele?.id,
                first_name: ele?.first_name,
                last_name: ele?.last_name,
                profile_image: ele?.profile_image || '#f2793d',
            };
        });
        return arr;
    }
    groupTypeArr(arr: any) {
        arr = arr.map((ele: any) => {
            return {
                id: ele?.id,
                name: ele?.name,
                profile_image: ele?.color_id,
            };
        });
        return arr;
    }

    async createProject() {
        this.apiService.showLoading();
        const formData = new FormData();
        const client = this.clientListData.find(
            (ele: any) => ele?.id === this.selectedClient[0]
        );
        if (client && 'name' in client) {
            delete client.name;
        }        
        if (client && 'isCheck' in client) {
            delete client.isCheck;
        }        

        if (
            this.projectDetails?.assignees
                .map((ele: any) => ele?.id)
                .includes(this.userRole.memberId) &&
            this.data.projectId
        ) {
            this.projectMembers.push(
                this.projectDetails?.assignees.find(
                    (ele: any) => ele?.id === this.userRole.memberId
                )
            );
        }

        if (
            this.projectDetails?.project_admin
                .map((ele: any) => ele?.id)
                .includes(this.userRole.memberId) &&
            this.data.projectId
        ) {
            this.projectManager.push(
                this.projectDetails?.project_admin.find(
                    (ele: any) => ele?.id === this.userRole.memberId
                )
            );
        }

        formData.append(
            'project_name',
            this.projectDetailForm.controls['projectName'].value
        );
        formData.append(
            'description',
            this.projectDetailForm.controls['projectDesc'].value
        );
        formData.append(
            'start_date',
            this.projectDetailForm.controls['projectStartDate'].value.getTime()
        );
        formData.append(
            'end_date',
            this.projectDetailForm.controls['projectEndDate'].value
                ? this.projectDetailForm.controls['projectEndDate'].value.getTime()
                : ''
        );
        if (client) {
            formData.append('client', JSON.stringify(client));
        }
        if (this.selectedGroup.length != 0) {
            formData.append(
                'groups',
                JSON.stringify(this.groupTypeArr(this.selectedMatPreview))
            );
            formData.append(
                'assignees',
                JSON.stringify([])
            );
        } else {
            formData.append(
                'groups',
                JSON.stringify([])
            );
            formData.append(
                'assignees',
                JSON.stringify(this.commonTypeArr(this.selectedMatPreview))
            );
        }
        this.projectManager = await this.getUniqueArray(this.projectManager,'id');
        formData.append(
            'project_admin',
            JSON.stringify(this.commonTypeArr(this.projectManager))
        );
        if (!this.data.projectId) {
            formData.append('owner', JSON.stringify(this.loginUserData));
        }
        formData.append('company', JSON.stringify(this.companyData));
        const co_members: any = [];
        this.outsiderEmailArr.forEach((ele: any) => {
            co_members.push({ id: ele.id, email: ele?.email });
        });
        formData.append('co_members', JSON.stringify(co_members?.length ? co_members : []));
        
        if (!this.data.projectId) {
            (await this.projectManagementService.createProject(formData)).subscribe(
                (res: any) => {
                    if (res?.message) {
                        this.toastrService.success(res?.message);
                    }
                    this.apiService.hideLoading();
                    this.closeDialog(true);
                    this.notificationService.setNotification(true);
                }
            );
        } else {
            (
                await this.projectManagementService.updateProject(
                    formData,
                    this.data.projectId
                )
            ).subscribe((res: any) => {
                if (res?.message) {
                    this.toastrService.success(res?.message);
                }
                this.apiService.hideLoading();
                this.closeDialog(true);
                this.notificationService.setNotification(true);
            });
        }
    }

    async getProjectDetailsById(projectId: any) {
        (
            await this.projectManagementService.getProjectDetails(projectId)
        ).subscribe(async (res: any) => {
            if (res) {
                this.projectDetails = res;
                this.ownerId = await this.projectDetails?.owner?.member_id;
                this.projectDetailForm.controls['projectName'].setValue(
                    res.project_name
                );
                this.projectDetailForm.controls['projectDesc'].setValue(
                    res.description
                );
                this.projectDetailForm.controls['projectAssignee'].setValue(
                    res.assignees.id
                );
                const selId: any[] = [];

                if (res.groups?.length == 0) {
                    this.selectedAssignees = res.assignees.map((item: any) => {
                        return {
                            ...item,
                            name: `${item.first_name} ${item.last_name}`,
                        };
                    });
                    res.assignees.forEach((element: any) => {
                        selId.push(element.id);
                    });
                    this.assigneeIncValue = selId.length;
                    this.selectedAssigneesFromBE = {
                        assignees: selId,
                        groups: [],
                        mainTask: true,
                    };
                } else {
                    this.selectedGroup = res.groups;
                    res.groups?.forEach((element: any) => {
                        selId.push(element.id);
                    });
                    this.incValue = selId.length;
                    this.selectedAssigneesFromBE = {
                        assignees: [],
                        groups: selId,
                        mainTask: true,
                    };
                    this.originalGroupListData.forEach((ele: any) => {
                        if (selId.includes(ele.id) && ele.is_deleted === true) {
                            this.deletedGroup.push(ele);
                            this.selectedGroup.length = 0;
                            this.selectedAssigneesFromBE = {
                                assignees: [],
                                groups: [],
                                mainTask: true,
                            };
                            this.selectedMatPreview.length = 0;
                        }
                    });

                }

                this.setAssigneeMatPreview();
                this.todayDate = new Date(res.start_date);
                !res.end_date
                    ? this.setNoDueDate({ checked: true })
                    : this.setNoDueDate({ checked: false });
                this.projectDetailForm.controls['projectStartDate'].setValue(
                    new Date(res.start_date)
                );
                this.projectDetailForm.controls['projectEndDate'].setValue(
                    res.end_date ? new Date(res.end_date) : ''
                );

                res.co_members.forEach((ele: any) => {
                    this.outsiderEmailArr.push({ email: ele?.email, id: ele.id });
                });
                this.clientListData = this.originalClientListData?.filter(
                    (ele: any) => {
                        if (res?.client?.id === ele.id) {
                            this.selectedClient.push(ele.id);
                            ele.isCheck = true;
                        }
                        return ele;
                    }
                );
                this.setMatPreview('client');
                const manager = res.project_admin?.map((v: any) => {
                    return v.id;
                });
                const project: any = [];
                const tempManager: any[] = [];
                for (const managerItem of this.projectManagerListData) {
                    if (await manager.includes(managerItem.id)) {
                        tempManager.push({ ...managerItem });
                        managerItem.ischeck = true;
                        this.projectManager.push(managerItem);
                    } else {
                        managerItem.ischeck = false;
                    }
                }                


                this.removeFromMemberList();

                const members = res.assignees?.map((v: any) => {
                    return v.id;
                });
                members.forEach((ele: any) => {
                    this.projectMembersListData.forEach((data: any) => {
                        if (ele === data.id) {
                            data.isCheck = true;
                            return data.isCheck;
                        }
                    });
                });
                this.projectMembers = await this.projectMembersListData.filter(
                    (ele: any) => ele.isCheck
                );

                this.allCheckManager = false;
                this.allCheckMember = false;
                this.allCheckMember = await this.updateAllCheck(
                    this.projectMembersListData
                );
                this.allCheckManager = await this.updateAllCheckManager(
                    this.projectManagerListData
                );
                this.projectManagerListData = await _.orderBy(
                    this.projectManagerListData,
                    'ischeck',
                    'desc'
                );
                this.projectMembersListData = _.orderBy(
                    this.projectMembersListData,
                    'isCheck',
                    'desc'
                );

                this.originalprojectMembersList = this.projectMembersListData;

                this.originalprojectManagerList = await this.projectManagerListData;


                this.selectedGroup.forEach((obj2) => {

                    const matchingElement = this.originalGroupListData.find(
                        (item) => item.id === obj2.id
                    );


                    if (matchingElement) {
                        this.projectManagerListData = this.projectManagerListData.filter(
                            (item: any) => !matchingElement.members.includes(item.id)
                        );
                    }
                });
            }
        });
        this.cdr.detectChanges();
    }
    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    async getCompanyDetails(id: any) {
        (await this.companyDetailService.getCompanyDetails(id)).subscribe(
            async (res: any) => {
                if (res) {
                    this.isShowClientList = res?.is_having_clients;
                }
            }
        );
    }
    async onSelectCheckBox(selectedData: any) {
        this.selectedAssigneesFromBE = selectedData;
        if (
            selectedData.groups.length === 0 &&
            selectedData.assignees.length === 0
        ) {
            this.selectedAssignees = [];
            this.selectedGroup = [];
            this.projectDetailForm.controls['projectAssignee'].setValue([]);
            this.projectDetailForm.controls['projectAssignee'].setErrors({
                required: true,
            });
            this.projectDetailForm.controls['projectAssignee'].setValidators(
                Validators.required
            );
            this.setAssigneeMatPreview();
            this.projectDetailForm.updateValueAndValidity();
            this.projectDetailForm.markAsDirty();
            this.projectManagerListData = this.universalManagerList;
            this.originalprojectManagerList = this.universalManagerList;
            this.resetManager();
            return;
        }
        if (selectedData.groups.length) {
            this.projectManagerListData = this.universalManagerList;
            this.selectedGroup = selectedData.groups;
            const uniqueSelectedIdArr = this.selectedGroup.filter(
                (ele: any, index: number) => index === this.selectedGroup.indexOf(ele)
            );
            this.selectedGroup = uniqueSelectedIdArr;
            this.selectedAssignees = [];
            this.projectDetailForm.controls['projectAssignee'].clearValidators();
            this.projectDetailForm.controls['projectAssignee'].setErrors(null);
            this.projectDetailForm.controls['projectAssignee'].setValue(
                this.selectedGroup
            );
            this.listArrName = 'Team';
            this.setAssigneeMatPreview();
            this.projectDetailForm.updateValueAndValidity();
            this.projectDetailForm.markAsDirty();
            this.setMatPreview('follower');
            let matchingObjectArray2: any;
            const memtemp: any[] = [];
            await this.selectedGroup.forEach(async (id1) => {
                this.projectDetails?.groups?.map((ele: any) => {
                    if (ele.id != id1) {
                        this.resetManager();
                    }
                });
                matchingObjectArray2 = await this.originalGroupListData.find(
                    (obj2) => obj2.id === id1
                );

                if (matchingObjectArray2 && matchingObjectArray2.members) {
                    memtemp.push(...matchingObjectArray2.members);
                }

            });
            const unique: any = _.uniq(memtemp);
            this.groupMembers = unique;
            unique.forEach((memberId: any) => {
                this.projectManagerListData = this.projectManagerListData.filter(
                    (obj3: any) => {
                        this.resetManager();
                        return obj3.id !== memberId;
                    }
                );
                this.originalprojectManagerList = this.projectManagerListData;
            });
            return;
        }
        if (selectedData.assignees.length) {
            this.managerChange = selectedData.assignees.length;
            this.assigneeChange = selectedData.assignees.length;
            this.selectedAssignees = selectedData.assignees;
            const uniqueSelectedIdArr = this.selectedAssignees.filter(
                (ele: any, index: number) =>
                    index === this.selectedAssignees.indexOf(ele)
            );
            this.selectedAssignees = uniqueSelectedIdArr;
            this.selectedGroup.length = 0;
            this.projectDetailForm.controls['projectAssignee'].clearValidators();
            this.projectDetailForm.controls['projectAssignee'].setErrors(null);
            this.projectDetailForm.controls['projectAssignee'].setValue(
                this.selectedAssignees
            );
            this.listArrName = 'assignee';
            this.setAssigneeMatPreview();
            this.projectDetailForm.updateValueAndValidity();
            this.projectDetailForm.markAsDirty();
            this.projectManagerListData = this.projectManagerListData.filter(
                (item: any) => {
                    this.resetManager();
                    return !selectedData.assignees.includes(item.id);
                }
            );
            this.selectedAssignees.forEach((id1) => {
                this.projectDetails?.assignees?.map((ele: any) => {
                    if (ele.id != id1) {
                        this.resetManager();
                    }
                });
            });
            this.originalprojectManagerList = this.projectManagerListData;
        }
    }
    async setAssigneeMatPreview() {
        if (this.selectedAssignees.length > 0) {
            this.selectedMatPreview = this.originalFollowerListData.filter(
                (ele: any) => {
                    if (this.selectedAssigneesFromBE?.assignees.includes(ele.id)) {
                        return ele;
                    } else {

                        this.projectManagerListData.push(ele);
                        const uniqueIds = new Set();

                        this.projectManagerListData = this.projectManagerListData.filter(
                            (item: any) => {
                                if (!uniqueIds.has(item.id) && !item.is_deleted) {
                                    uniqueIds.add(item.id);
                                    return true;
                                }
                                return false;
                            }
                        );
                    }
                }
            );
        } else if (this.selectedGroup?.length > 0) {
            this.selectedMatPreview = this.originalGroupListData.filter(
                (ele: any) => {
                    if (this.selectedGroup.includes(ele.id)) {
                        return ele;
                    }
                }
            );
        } else {
            this.selectedMatPreview = [];
        }
    }
    async removeEmit() {
        this.projectManagementService.removeUserData(this.projectManager);
        if (this.data.project_id) {
            this.logincreator = await this.ownerId;
        }
    }
    resetManager() {
        if (this.selectedAssigneesFromBE?.assignees?.length != this.assigneeIncValue) {
            this.originalprojectManagerList.forEach((element: any) => {
                if (this.selectedAssigneesFromBE?.assignees.includes(element.id)) {
                    element.ischeck = false;
                    const indexInProjectManager = this.projectManager.findIndex(item => item.id === element.id);

                    if (indexInProjectManager !== -1) {
                        this.projectManager.splice(indexInProjectManager, 1);
                    }
                    return element;
                }
            });
            this.projectManagerListData = this.originalprojectManagerList;
            const tempManager: any[] = [];
            this.projectManagerListData = this.projectManagerListData.filter(
                (item: any) => {
                    return !this.selectedAssigneesFromBE.assignees.includes(item.id);
                }
            );
            this.projectManagerListData = this.projectManagerListData.reduce((uniqueList: any[], currentItem: any) => {
                if (!uniqueList.some(item => item.id === currentItem.id)) {
                    uniqueList.push(currentItem);
                }
                return uniqueList;
            }, []);
            this.removeFromMemberList();
        }

        if (this.selectedAssigneesFromBE?.groups?.length != this.incValue) {
            for (const element of this.projectManager) {
                if (this.groupMembers.includes(element.id)) {

                    const indexInProjectManager = this.projectManager.findIndex(item => item.id === element.id);

                    if (indexInProjectManager !== -1) {
                        this.originalprojectManagerList.forEach((elementz: any) => {
                            elementz.ischeck = false;
                        });
                        this.projectManager.splice(indexInProjectManager, 1);
                    }
                }
            }
            this.projectManagerListData = this.originalprojectManagerList;
            this.projectManagerListData = this.projectManagerListData.filter(
                (item: any) => !this.selectedAssigneesFromBE.assignees.includes(item.id)
            );
            this.projectManagerListData = this.projectManagerListData.reduce((uniqueList: any[], currentItem: any) => {
                if (!uniqueList.some(item => item.id === currentItem.id)) {
                    uniqueList.push(currentItem);
                }
                return uniqueList;
            }, []);


            this.removeFromMemberList();
        }
    }
    onMenuOpened() {
        this.inputField.nativeElement.value = '';
        this.projectManagerListData = this.originalprojectManagerList;
        this.allCheckManager = false;
    }

    async getUniqueArray(arr:any, prop:string) {
        return arr.filter((obj:any, index:number, self:any) => {
            return index === self.findIndex((o:any) => o[prop] === obj[prop]);
        });
    }
}